import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDmyZ22rFftD1DZadMMzEXaZjEAsBj51p4",
  authDomain: "shreddersbay-3aee0.firebaseapp.com",
  databaseURL: "https://shreddersbay-3aee0.firebaseio.com",
  projectId: "shreddersbay-3aee0",
  storageBucket: "shreddersbay-3aee0.appspot.com",
  messagingSenderId: "254504701779",
  appId: "1:254504701779:web:107c83e48c363679589ba6",
  measurementId: "G-252HHVFFLB"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Request permission and get FCM token
export const requestFirebaseNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: "BB9dQSD7IHBrhW24JvjORSeKhi1NUmEsjnxs6H8AwQkoi7uu8_1nYyOXuHx_cpCGM4fwiJHq42kQ6yG5WaAGjac",
        serviceWorkerRegistration: await navigator.serviceWorker.register('/firebase-messaging-sw.js')
      });
      console.log('FCM Token:', token);
      return token;
    } else {
      console.error('Notification permission denied');
    }
  } catch (error) {
    console.error('Error while getting notification permission', error);
  }
};
