import React, { useState, useEffect } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Tab, Box, ListItemIcon, useMediaQuery, IconButton, Drawer, List, ListItem, Grid, Card, CardContent,ListItemText, Divider, Snackbar, Link , Alert} from '@mui/material';
import { Tabs } from '@mui/material';
import { useCallback, useContext } from 'react';
import { Searchbar } from './Searchbar';
import SearchProduct from './SearchProduct';
import SmsIcon from '@mui/icons-material/Sms';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ChatIcon from '@mui/icons-material/Chat';
import Avatar from '@mui/material/Avatar';
import Modal from '@mui/material/Modal';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import MySell from '../Sell/MySell';
import { useNavigate, } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Dialog from '@mui/material/Dialog';
import { ImagesApp } from '../../Images/ImagesApp';
import { BsBoxSeam } from "react-icons/bs";
import { MdOutlineFeedback } from "react-icons/md";
import { MdOutlineReviews } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { IoIosHeartEmpty } from "react-icons/io";
import { IoHomeOutline } from "react-icons/io5";
import { FaRocketchat } from "react-icons/fa6";
import { FaEllipsisVertical } from "react-icons/fa6";
import { IoMdNotificationsOutline } from "react-icons/io";

import CustomModalSignin from './CustomModalSignin';
import CustomModalSignup from './CustomModalSignup';
// import {Context} from "../../Utils/ContextAPI/ContextProvider";
import { Context } from '../../Utils/ContextAPI/ContextProvider';
import { isUserExits } from '../../Constants/Functions';
import Popover from '@mui/material/Popover';
import { app } from '../../Utils/fire_base/firebase_setup';
import './Popover.css';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { create_user_in_firebase, firebaseSignIn, firebaseSignup, get_user_data } from '../../Constants/Firebase_functions';
const auth = getAuth();

const style = {
  position: 'absolute',
  top: '20%',
  left: '70%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const menubox_name_with_apptitle = {
  display: "flex",

};

const modal_content = {
  display: "flex",

};
const menubox_button = {
  width: "100%",
  marginBottom: 2,
  marginTop: 2,
}
const Header = () => {


  const [gState, setGState, LogOut] = useContext(Context)
  const { user_name, user_id, user_email } = gState;
  const [value, setValue] = useState(0); // Default to the first tab
  const [open, setOpen] = React.useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [openSignUp, setopenSignUp] = useState(false);
  const [setting, setSetting] = React.useState(false);
  const [notification, setNotification] = React.useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState('')
  const [Passwords, setPasswords] = useState('')
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [firbaseUID, setFirbaseUID] = useState('');
  const [notifications, setNotifications] = useState([]);
 
  
  useEffect(() => {
    const sparkleContainer = document.querySelector('.sparkle-container');
    
    if (sparkleContainer) {
      for (let i = 0; i < 10; i++) {
        const sparkle = document.createElement('div');
        sparkle.className = 'sparkle';
        sparkle.style.top = `${Math.random() * 100}%`;
        sparkle.style.left = `${Math.random() * 100}%`;
  
        // Random color generation
        const randomColor = `hsl(${Math.floor(Math.random() * 360)}, 70%, 75%)`; // Bright pastel color
        sparkle.style.backgroundColor = randomColor;
  
        sparkleContainer.appendChild(sparkle);
        console.log('Sparkle created:', sparkle); // Check this in console
      }
    }
  }, []);
  

  const handleCloseNotification = () =>
    setNotification(prestate => !prestate);


  const handleCloseSetting = () => setSetting(prestate => !prestate);

  const menubox_style1 = {
    position: 'absolute',
    top: '20%',
    left: '80%',
    transform: 'translate(-50%, -50%)',
    width: '20%',
    background: 'white',
    border: '2px solid #eee',
    boxShadow: 24,
    padding: '8px',

    [theme.breakpoints.down('sm')]: {
      width: '70%', // Change width to 80% on mobile view
      left: '54%',  // Change left position to 70%
    },

  };

  const menubox_style2 = {
    position: 'absolute',
    top: '20%',
    left: '80%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    background: 'transparent',
    border: '1px solid #eee',
    boxShadow: 24,
    padding: '5px',

    [theme.breakpoints.down('sm')]: {
      width: '70%', // Change width to 80% on mobile view
      left: '54%',  // Change left position to 70%
    },

  };

  const handleButtonClick = () => {
    console.log('Sell button clicked');
    navigate('/sell');
  };


  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch("https://shreddersbay.com/API/notification_api.php?action=select");
        const data = await response.json();
        setNotifications(data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };


  const handleFirebaseSignIn = async () => {

    handleSync();

  }
  const handleSync_Exits = async () => {
    await firebaseSignIn(email, password, handleLogin_Exists)

  }
  const handleSync = async () => {
    await firebaseSignIn(email, password, handleLogin)

  }
  const handleLogin = async () => {
    // Create a FormData object and append the form values
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    // if (password !== 'password123') {
    //   setPasswordError(true);         // Trigger error popup
    //   setErrorMessage('The password is incorrect!');
    //   return;
    // } else {
    //   // Call the provided login function if the password is correct
    //   handleLogin();
    // }

    try {
      const response = await fetch('https://shreddersbay.com/API/user_api.php?action=signin', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      console.log("Result for success=>", result);

      // Check the response structure based on what your API returns
      if (result.message = "Login Successfully") {

        // Store any value in localStorage
        localStorage.setItem("@u_name", result["0"].name);
        localStorage.setItem("@u_id", result["0"].id);
        localStorage.setItem("@u_email", result["0"].email);
        localStorage.setItem("@fuid", result[0].firebase_uid);

        setGState({
          ...gState,
          user_name: result["0"].name,
          user_email: result["0"].email,
          user_id: result["0"].id,
          firebase_uid: result["0"].firebase_uid
        })
        console.log("result is =>", result);
        get_user_data(result["0"].firebase_uid).then((data) => {
          console.log("data with login success in header", data)

          if (data) {

          } else {

            create_user_in_firebase(result['0'].firebase_uid, result["0"].email, result["0"].name, result["0"].mobile);
          }
        }).catch((data) => {
          console.log("data with login failed in header", data);
        })
        // Optionally, close the login dialog
        setOpenLogin(!openLogin);

        setEmail("")
        setPassword("")


        alert(result.message);
      } else {
        // Alert the user about login failure
        console.log("login failed")
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred during login');
    }
  };

  const handleLogin_Exists = async () => {
    // Create a FormData object and append the form values
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    try {
      const response = await fetch('https://shreddersbay.com/API/user_api.php?action=signin', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      console.log("Result for success=>", result);

      // Check the response structure based on what your API returns
      if (result.message = "Login Successfully") {

        // Store any value in localStorage
        localStorage.setItem("@u_name", result["0"].name);
        localStorage.setItem("@u_id", result["0"].id);
        localStorage.setItem("@u_email", result["0"].email);
        localStorage.setItem("@fuid", result[0].firebase_uid);

        setGState({
          ...gState,
          user_name: result["0"].name,
          user_email: result["0"].email,
          user_id: result["0"].id,
          firebase_uid: result["0"].firebase_uid
        })
        console.log("result is =>", result);
        // create_user_in_firebase(result['0'].firebase_uid, result["0"].email, result["0"].name, result["0"].mobile);
        // Optionally, close the login dialog
        setOpenLogin(!openLogin);

        setEmail("")
        setPassword("")


        alert(result.message);
      } else {
        // Alert the user about login failure
        console.log("login failed")
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred during login');
    }
  };

  const handleFirebaseSignup = async () => {
    await firebaseSignup(emails, Passwords, handleSignUp);
  }

  const handleSignUp = async (fuid) => {


    if (Passwords !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    if (name !== '' && emails !== "" && phone !== "" && Passwords !== '' && fuid !== "") {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', emails);
      formData.append('mobile', phone);
      formData.append('password', Passwords);
      formData.append("firebase_uid", fuid);
      console.log(`name =${name} emails=${emails} mobile =${phone} password =${Passwords} firebaseuid =${fuid}`);

      try {
        const response = await fetch('https://shreddersbay.com/API/user_api.php?action=signup', {
          method: 'POST',
          body: formData,
        });

        const result = await response.json();

        if (result.message !== "Email Already Exist") {
          alert(`${result.message}`);
          // Optionally, you can redirect the user or open the sign-in modal
          setName("");
          setEmails("");
          setPasswords("");
          setConfirmPassword("");
          setPhone("");
          handleCloseSignup();
        } else {
          alert(result.message || 'Signup failed');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred during signup');
      }
    } else {
      console.log("All field is incomplete...");
    }


  };

  const handleClickOpen = () => {

    setOpenLogin(true);
  }




  const handleOpenSignup = () => {
    setopenSignUp(true);
    setOpenLogin(false);
  }

  const navigateEdit = () => {
    navigate('/MyAddress');
  }

  const navigateCurrent = () => {
    navigate('/Currentorder');
  }

  const handleOpenSignin = () => {
    setOpenLogin(true);
    setopenSignUp(false);
  }

  const handleCloseLogin = () => {
    setOpenLogin(false);
  }

  const handleCloseSignup = () => {
    setopenSignUp(false);
  }


  const navigatefeedback = () => {
    navigate('/feedback')

  }

  const navigatereview = () => {
    navigate('/reviews')

  }


  const navigateProfile = () => {
    navigate('/profile/:user_id');
  }


  const handleLogoutweb = async () => {
    try {
      // Perform the logout operation
      await LogOut();
      // If logout is successful, close the modal
      setSetting(prestate => !prestate);
    } catch (error) {
      // Handle any errors that occur during logout
      console.error('Logout error:', error);
    }
  }


  const handleChat = () => {
    navigate('/chatinbox');
  }

  const navigateHome = () => {
    navigate('/');
  }

  const navigateOrderAuction = () => {
    navigate('/orderauctiondetail');
  }




  const drawer = (
    <Box
      sx=
      {{
        width: 300,
        backgroundColor: '#f0f0f0', // Change this to your desired color
        height: '100vh', // Optional: to make the drawer full height
      }}
      role="presentation"
      onClick={handleDrawerToggle}
      onkeyDown={handleDrawerToggle}
    >


      <Box sx={{
        backgroundColor: '#fff', // White background
        width: 270,
        padding: '16px', // Padding for the inner content
        margin: '10px', // Margin from the outer layer
        borderRadius: '8px', // Less border radius
        boxShadow: 0.2, // Optional: add a shadow for better visibility
      }}>
        <Box sx={menubox_name_with_apptitle} >
          <Box sx={{ marginRight: '20px' }} >
            <Avatar >H</Avatar>
          </Box>

          <Box sx={{ fontSize: '18px', alignContent: 'center', fontWeight: 500 }}>
            Shreddersbay
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: '#fff', // White background
          width: 270,
          padding: '16px', // Padding for the inner content
          margin: '10px', // Margin from the outer layer
          borderRadius: '8px', // Less border radius
          boxShadow: 0.2, // Optional: add a shadow for better visibility
        }}>
        <List>



          <ListItem onClick={navigateHome}>
            <ListItemIcon><IoHomeOutline sx={{ fontWeight: 700 , cursor:'pointer'}} size={20}   /></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          <ListItem onClick={navigateOrderAuction}>
            <ListItemIcon><BsBoxSeam sx={{ fontWeight: 700 , cursor:'pointer'}} size={20}   /></ListItemIcon>
            <ListItemText primary="My Order" />
          </ListItem>

          <ListItem onClick={navigateCurrent}>
            <ListItemIcon><IoIosHeartEmpty sx={{ fontWeight: 700, cursor:'pointer' }} size={20}  />
            </ListItemIcon>
            <ListItemText primary="My ADS" />
          </ListItem>

          <ListItem onClick={navigatefeedback} >
            <ListItemIcon><MdOutlineFeedback sx={{ fontWeight: 700, cursor:'pointer' }} size={20}   />
            </ListItemIcon>
            <ListItemText primary="My Feedback" />
          </ListItem>



          <ListItem onClick={navigatereview} >
            <ListItemIcon> <MdOutlineReviews sx={{ fontWeight: 700, cursor:'pointer' }} size={20}  />
            </ListItemIcon>
            <ListItemText primary="My Reviews" />
          </ListItem>


          <ListItem onClick={navigateEdit}>
            <ListItemIcon> <IoLocationOutline sx={{ fontWeight: 700, cursor:'pointer' }} size={20} /></ListItemIcon>
            <ListItemText primary="My Address" />
          </ListItem>


          <ListItem onClick={handleChat}>
            <ListItemIcon><FaRocketchat sx={{ fontWeight: 700, cursor:'pointer' }} size={20}    /></ListItemIcon>
            <ListItemText primary="Chat" />
          </ListItem>



          <ListItem onClick={handleLogoutweb}>
            <ListItemIcon> <RiLogoutCircleRLine sx={{ fontWeight: 700, cursor:'pointer' }} size={20}  /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Box>

      <Box
        sx={{
          display: 'flex', // To align items horizontally
          alignItems: 'center', // Vertically center items
          gap: 2, // Adds space between the image and the text
          position:'absolute',
          bottom: 0,
          padding:'20px',
          backgroundColor:'lightgray',
        }}
      >
        <Box>
          <img
            src={ImagesApp.downloadApp}
            alt="ShreddersBay App"
            style={{ width: '70px', height: '70px' }} // Set the size of the image
          />
        </Box>

        <Box>
          <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'0.2' }}>
            Download ShreddersBay app
          </Typography>
          <button>Open App</button>
        </Box>
      </Box>


    </Box>
  )

  return (
    <React.Fragment>

      <AppBar
        sx={{
          background: "#00457E",
          marginBottom: '10px',
          zIndex: 10,
          height: " 60px",
        }}
      >

        
      <div className="sparkle-container">
        {/* Sparkles will appear here */}
      </div>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between', // Space out the content
            alignItems: 'center',
            width: '100%',
          }}
        >
          {/* Left side of the toolbar */}
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ marginRight: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}



          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IoHomeOutline
              sx={{ marginRight: 2, cursor: 'pointer' }}
              size={25}
              onClick={navigateHome}
            />


          </Typography>


          <Typography
            sx={{
              display: { xs: 'flex', sm: 'none', md: 'none' },
              alignItems: 'center',
            }}
          >
            <IoMdNotificationsOutline
              sx={{ cursor: 'pointer' }}
              size={25}
              onClick={handleCloseNotification} />


          </Typography>



          {/* Popover container */}
          <div className="popover-container"
            style={{
              marginRight: { xs: '45px', md: 0 },
              marginLeft: { xs: 0, sm: '10px', md: '10px' },
            }}
          >
            <FaEllipsisVertical className="fa-ellipsis-vertical" />
            <div className="popover-message">
              <Box
                sx={{
                  border: '1px solid lightgray',
                  padding: '4px 4px',
                  background: 'linear-gradient(90deg, #0960b9, #9b9292)',
                  textAlign: 'center',
                  zIndex: 0,
                }}
              >
                <Link
                  href="https://play.google.com/store/apps/details?id=com.shreddersbay"
                  target="_blank"
                  sx={{
                    color: 'inherit',
                    textDecoration: 'inherit',
                    '&:hover': {
                      color: 'white',
                      textDecoration: 'none',
                    },
                  }}
                >
                  Shreddersbay App
                </Link>
              </Box>
            </div>
          </div>

          {/* Searchbar and Tabs for larger screens */}
          {!isMobile && (
            <>
              <Box sx={{ fontSize: '1.5rem', marginInline: 2 }}
              >
                Ｓ𝘩𝕣𝘦𝕕𝖉𝛠𝖗𝒔Ꮟ𝝰ʏ
              </Box>

              <Box>
                <SearchProduct />
              </Box>



              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginLeft: 2, // Add some space from the home icon
                  cursor: 'pointer', // Change cursor to pointer to indicate clickability
                  '&:hover': {
                    color: 'white', // Optional: Change color on hover
                  },
                }}
                onClick={handleChat}  // Navigate to chat component on click
              >
                <FaRocketchat sx={{ color: 'white', }} size={20} /><Box sx={{ marginLeft: 2 }}>Chat</Box>
              </Box>


              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginLeft: 2, // Add some space from the home icon
                  cursor: 'pointer', // Change cursor to pointer to indicate clickability
                  '&:hover': {
                    color: 'white', // Optional: Change color on hover
                  },
                }}
              // onClick={handleNotification}  // Navigate to chat component on click
              >

                <IoMdNotificationsOutline
                  sx={{ color: 'white' }} size={20}
                  onClick={handleCloseNotification}
                />


              </Box>

            </>
          )}

          {/* Avatar/Login Section (Right-aligned) */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'flex-end', sm: 'flex-end', md: 'flex-end' },
              marginRight: { xs: '30px', sm: '0' },
              width: 'auto',
            }}
          >
            {isUserExits(user_name, user_email, user_id) ? (
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                sx={{ width: 36, height: 36, cursor: 'pointer' }}
                onClick={handleCloseSetting}
              />
            ) : (
              <Button variant="body1" onClick={handleClickOpen}>Login</Button>
            )}
          </Box>

          {/* Sell Button (Always Right-Aligned) */}
          <Box
            sx={{
              display: 'flex',
              marginLeft: '20',
              alignItems: 'center',
            }}
          >
            <Button variant="contained" onClick={handleButtonClick}>Sell</Button>
          </Box>
        </Toolbar>
      </AppBar>


      <Modal
        open={notification}
        onClose={handleCloseNotification}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ marginTop: '3%' }}
      >
        <Box sx={menubox_style2}  >
          <Grid container spacing={1}>
            {notifications.map((notification) => (
              <Grid item xs={12} key={notification.notification_id}>
                <Card
                  sx={{
                    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
                    borderRadius: "7px",

                    transition: "transform 0.3s ease-in-out",
                    '&:hover': {
                      transform: "translateY(-5px)",
                      boxShadow: "0px 15px 20px rgba(0, 0, 0, 0.15)",
                    },
                  }}
                >
                  <CardContent
                    sx=
                    {{
                      padding: '10px',
                      '&:last-child': {
                        paddingBottom: '10px',
                      },
                      mb: 0
                    }}>


                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center', // Align vertically centered
                        width: '100%', // Ensure it takes the full width
                      }}
                    >
                      <Typography variant="body1" sx={{ mb: 1, color: "#555", fontSize: '10px' }}>
                        {notification.description}
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={{ fontSize: "0.5rem", color: "lightgray", textAlign: 'right' }}
                      >
                        {new Date(notification.date).toLocaleString([], {
                          year: 'numeric',
                          month: 'short',  // Displays abbreviated month (e.g., Jan, Feb)
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </Typography>


                    </Box>


                  </CardContent>

                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>


      <Modal
        open={setting}
        onClose={handleCloseSetting}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ marginTop: '15%' }}
      >

        <Box sx={menubox_style1} >
          <Box sx={menubox_name_with_apptitle} >
            <Box sx={{ marginRight: '20px' }} >
              <Avatar >H</Avatar>
            </Box>

            <Box sx={{ fontSize: '18px', alignContent: 'center', fontWeight: 500 }}>
              Shreddersbay
            </Box>
          </Box>
          <Box sx={menubox_button} >
            <Button
              variant='contained'
              fullWidth
              size="small"
              sx={{
                backgroundColor: '#4CAF50',  // Add your desired background color
                '&:hover': {
                  backgroundColor: '', // Optional: change color on hover
                },
              }}
              onClick={navigateProfile}>View and Edit Profile
            </Button>
          </Box>


          <Box
            sx=
            {{
              paddingTop: 1,
              paddingBottom: 1,
              display: 'flex',
              borderTop: '1px solid lightgray',
              '&:hover': {
                backgroundColor: '#c9f8cbba',
                color: '#4CAF50',
                borderColor: '#c9f8cbba',
              },
            }} onClick={navigateOrderAuction} >
            <Box sx={{ marginRight: '20px' }} >
              <BsBoxSeam sx={{ fontWeight: 700 }} size={20} />
            </Box>

            <Box sx={{ fontSize: '15px', alignContent: 'center', fontWeight: 400 , cursor: 'pointer'}}>
              My Order
            </Box>


          </Box>

          <Box
            sx=
            {{
              paddingTop: 1,
              paddingBottom: 1,
              display: 'flex',
              borderTop: '1px solid lightgray',
              '&:hover': {
                backgroundColor: '#c9f8cbba',
                color: '#4CAF50',
                borderColor: '#c9f8cbba',
              },
            }}
            onClick={navigateCurrent} >
            <Box sx={{ marginRight: '20px' }} >
              <IoIosHeartEmpty sx={{ fontWeight: 700 }} size={20} />

            </Box>

            <Box sx={{ fontSize: '15px', alignContent: 'center', fontWeight: 400,cursor: 'pointer' }}>
              My ADS
            </Box>


          </Box>

          <Box
            sx=
            {{
              paddingTop: 1,
              paddingBottom: 1,
              display: 'flex',
              borderTop: '1px solid lightgray',
              '&:hover': {
                backgroundColor: '#c9f8cbba',
                color: '#4CAF50',
                borderColor: '#c9f8cbba',
              },
            }}
          >
            <Box sx={{ marginRight: '20px' }} >
              <MdOutlineFeedback />

            </Box>

            <Box sx={{ fontSize: '15px', alignContent: 'center', fontWeight: 400,cursor: 'pointer' }} onClick={navigatefeedback}>
              My Feedback
            </Box>
          </Box>

          <Box
            sx=
            {{
              paddingTop: 1,
              paddingBottom: 1,
              display: 'flex',
              borderTop: '1px solid lightgray',
              '&:hover': {
                backgroundColor: '#c9f8cbba',
                color: '#4CAF50',
                borderColor: '#c9f8cbba',
              },
            }}
          >
            <Box sx={{ marginRight: '20px' }} >
              <MdOutlineReviews sx={{ fontWeight: 700 }} size={20} />

            </Box>

            <Box sx={{ fontSize: '15px', alignContent: 'center', fontWeight: 400,cursor: 'pointer' }} onClick={navigatereview}>
              My Reviews
            </Box>
          </Box>



          <Box
            sx=
            {{
              paddingTop: 1,
              paddingBottom: 1,
              display: 'flex',
              borderTop: '1px solid lightgray',
              '&:hover': {
                backgroundColor: '#c9f8cbba',
                color: '#4CAF50',
                borderColor: '#c9f8cbba',
              },
            }}
            onClick={navigateEdit}>
            <Box sx={{ marginRight: '20px' }} >
              <IoLocationOutline sx={{ fontWeight: 700 }} size={20} />


            </Box>
            <Box sx={{ fontSize: '15px', alignContent: 'center', fontWeight: 400,cursor: 'pointer' }} >
              My Address
            </Box>
          </Box>


          <Box
            sx=
            {{
              paddingTop: 1,
              paddingBottom: 1,
              display: 'flex',
              borderTop: '1px solid lightgray',
              '&:hover': {
                backgroundColor: '#c9f8cbba',
                color: '#4CAF50',
                borderColor: '#c9f8cbba',
              },
            }}
          >
            <Box sx={{ marginRight: '20px' }} >
              <RiLogoutCircleRLine sx={{ fontWeight: 700 }} size={20} />

            </Box>




            <Box sx={{ fontSize: '15px', alignContent: 'center', fontWeight: 400 ,cursor: 'pointer'}} onClick={handleLogoutweb}>
              Logout
            </Box>



          </Box>


        </Box>
      </Modal>





      <CustomModalSignin
        open={openLogin}
        handleClose={handleCloseLogin}
        handleLogin={handleFirebaseSignIn}
        handleOpenSignup={handleOpenSignup}
        email={email}
        setEmail={setEmail}
        password={password}
        handleonchangepassword={(e) => setPassword(e.target.value)}
        ImagesApp={ImagesApp}

      />


            {/* <Snackbar open={passwordError} autoHideDuration={3000} onClose={() => setPasswordError(false)}>
        <Alert onClose={() => setPasswordError(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> */}

      <CustomModalSignup
        open={openSignUp}
        handleClose={handleCloseSignup}
        handleSignUp={handleFirebaseSignup}
        handleOpenSignin={handleOpenSignin}
        images={ImagesApp.signupshreds}
        name={name}
        setName={setName}
        email={emails}
        setEmail={setEmails}
        password={Passwords}
        setPassword={setPasswords}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        phone={phone}
        setPhone={setPhone}
      />


      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>

    </React.Fragment>
  )
}

export default Header
