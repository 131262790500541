import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Divider } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CardMedia from '@mui/material/CardMedia';
import { firstImg } from '../../Constants/Functions';
import { useNavigate } from 'react-router-dom';

import { Context } from '../../Utils/ContextAPI/ContextProvider';


const OrderSearch = () => {
  const location = useLocation();
  const [orders, setOrders] = useState([]);
  const imgurl = "https://shreddersbay.com/API/uploads/";
  const navigate = useNavigate();
  const [gState, setGState, LogOut, gProductForm, setgProductForm] = useContext(Context);
  const { user_id, user_name, user_email } = gState;


  // Extract product name from query params
  const params = new URLSearchParams(location.search);
  const productName = params.get('productName');

  // const handledetails = async (bookingId, item) => {
  //   if (item && item.filename) { // Check if item and item.filename exist
  //     const allimgsArray = item.filename.split(','); // Split the images
  //     console.log("setallimgs=:>>", allimgsArray); // Ensure this prints an array of images

  //     const formData = new FormData();
  //     formData.append('booking_id', bookingId);
  //     const apiurl = "https://shreddersbay.com/API/orders_api.php?action=select_id";

  //     try {
  //       const result = await fetch(apiurl, {
  //         method: 'POST',
  //         body: formData,
  //       });

  //       if (result.ok) {
  //         const data = await result.json();
  //         console.log('view order', data);
  //         navigate('/orderdetails', { state: { orderData: data[0], allimg: allimgsArray } });
  //       }

  //     } catch (error) {
  //       console.error('Error fetching order details:', error);
  //     }
  //   } else {
  //     console.error('Filename is undefined or missing.');
  //   }
  // };

  useEffect(() => {
    if (productName) {
      // Create FormData object
      const formData = new FormData();
      formData.append('productName', productName);

      // Send request with FormData
      fetch('https://shreddersbay.com/API/orders_api.php?action=productfilter', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data && data.length > 0) {
            setOrders(data); // Store the array of orders
          } else {
            setOrders([]); // Clear the results if no data is found
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
    }
  }, [productName]);
  const handledetails = async (bookingId, item) => {
    if (item && item.filename) { // Check if item and item.filename exist
      const allimgsArray = item.filename.split(','); // Split the images
      console.log("setallimgs=:>>", allimgsArray); // Ensure this prints an array of images

      const formData = new FormData();
      formData.append('booking_id', bookingId);
      const apiurl = "https://shreddersbay.com/API/orders_api.php?action=select_id";

      try {
        const result = await fetch(apiurl, {
          method: 'POST',
          body: formData,
        });

        if (result.ok) {
          const data = await result.json();
          console.log('view order', data);
          navigate('/orderdetails', { state: { orderData: data[0], allimg: allimgsArray } });
        }

      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    } else {
      console.error('Filename is undefined or missing.');
    }
  };

  return (
    <Box sx={{ padding: '10%' }}>
      {/* Display search results */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between', // Adjusts the space between cards
          gap: 2,
          padding: 2, // Adds padding to the container

        }}
      >
        {orders.length > 0 ? (
          orders.map((order, item) => (
            <Card key={order.booking_id}
              sx={{
                maxWidth: '30%', // Ensures three cards per row with some space in between
                flex: '1 1 calc(33.333% - 16px)', // Ensures each card takes 1/3 of the row minus the gap
                height: 300,
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                position: 'relative',
                transition: 'transform 0.3s ease-in-out, background-color 0.3s ease-in-out',
                '&:hover': {
                  backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0.3, 0.4, 0.5, 0.2))', // Apply linear gradient
                  transform: 'scale(1.05)', // Slightly scale the card on hover
                  '& .hoverDetail': {
                    opacity: 1, // Show the detail text on hover
                  },
                },
                '@media (max-width: 768px)': {
                  maxWidth: '48%',
                  flex: '1 1 calc(50% - 16px)',
                },
                '@media (max-width: 480px)': {
                  maxWidth: '68%',
                  flex: '1 1 calc(50% - 16px)',
                },
              }}
              onClick={() => handledetails(order.booking_id, order)}
            >


              {
                order ? (
                  <CardMedia
                    sx={{ height: 140 }}
                    image={`${imgurl}${firstImg(order.filename)}`}
                  />
                ) : (<Skeleton variant="rectangular" width={210} height={118} />

                )}


              <CardContent sx={{ flexGrow: 1, textAlign: 'left' }}>
                <Typography gutterBottom variant="body2"
                  style={{ color: '#00457E', fontSize: '20px', fontWeight: '700', }} >
                  {order ? order.p_name : ""}
                </Typography>
                <Typography variant="body2"
                  style={{ color: 'gray', fontSize: '16px', fontWeight: '700', }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: 15, color: "#00457E" }} /> {order ? order.price : ""}
                </Typography>
                <Typography variant="body2"
                  style={{ color: 'lightgray', fontSize: '14px', }}>
                  <LocationOnOutlinedIcon sx={{ fontSize: 15, color: "#00457E" }} />  {order ? order.address : ""}
                </Typography>
                <Typography variant="caption"
                  style={{ color: 'lightgray', fontSize: '14px', }}
                >
                  {order ? order.state_name : ""}
                </Typography>

                <Typography variant="body2"
                  style={{ color: 'lightgray', fontSize: '12px', fontWeight: '700', }}
                >
                  <CalendarTodayOutlinedIcon sx={{ fontSize: 15, color: "#00457E" }} /> {order ? order.date : ""}
                </Typography>
              </CardContent>


              <Box
                className="hoverDetail"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  opacity: 0, // Hidden by default
                  transition: 'opacity 0.3s ease-in-out', // Smooth transition for showing/hiding
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
              >
                Detail
              </Box>

              {/* <button onClick={() => handledetails(order.booking_id, order)}>Details order</button> */}
            </Card>
          ))
        ) : (
          <Typography variant="body1" color="text.secondary" style={{ textAlign: 'center', marginTop: '16px' }}>
            No results found.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default OrderSearch;
