import React, { useEffect, useState, useContext } from 'react'
import { Box, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import '../Sell/MySell.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';
import { Url } from '../../Constants/Apiurls';
import UserAuth from '../Credential/UserAuth';
import { Context } from '../../Utils/ContextAPI/ContextProvider';

function ProductForm() {

   const navigate = useNavigate();
   const [gState, setGState, LogOut, gProductForm, setgProductForm, catagorySelectedData, setcatagorySelectedData] = useContext(Context);
   const { user_name, user_id, user_email } = gState;
   const [data, setData] = useState([]);
   // const [selectedData, setSelectedData] = useState([]);
   const [title, setTitle] = useState('');
   const [error, setError] = useState(false);
   const [helperText, sethelperText] = useState('');
   const [desc, setDesc] = useState('');
   const [descerror, setdescError] = useState(false);
   const [deschelperText, setdeschelperText] = useState('');
   const [price, setPrice] = useState('');
   const [priceerror, setpriceError] = useState(false);
   const [pricehelperText, setpricehelperText] = useState('');
   const [Weight, setWeight] = useState('');
   const [weighterror, setweightError] = useState(false);
   const [weighthelperText, setweighthelperText] = useState('')

   const [isUserAuthEnable, setIsUserAuthEnable] = useState(false);

   useEffect(() => {
      fetch(Url.get.Producturl).then((result1) => {
         result1.json().then((resp) => {
            setData(resp)
         })
      })
   }, [])
   console.log("result1", data)

   //   useEffect(() => {
   //      const storedTitle = localStorage.getItem('title');
   //      const storedDescription = localStorage.getItem('description');
   //      const storedPrice = localStorage.getItem('Price');
   //      const storedWeight = localStorage.getItem('weight');

   //      const storedSelectedData = JSON.parse(localStorage.getItem('selectedData') || '[]');


   //      console.log("title1", localStorage.getItem('title')); // Output: Sample Title
   //      console.log("description", localStorage.getItem('description')); // Output: This is a sample description
   //      console.log(localStorage.getItem('price')); // Output: 100
   //      console.log(localStorage.getItem('weight')); // Output: 1.5
   //      console.log(JSON.parse(localStorage.getItem('selectedData'))); // Output: [{ id: 1, name: 'Example' }]


   //      if (storedTitle) setTitle(storedTitle);
   //      if (storedDescription) setDesc(storedDescription);
   //      if (storedPrice) setPrice(storedPrice);
   //      if (storedWeight) setWeight(storedWeight);
   //      if (storedSelectedData.length) setcatagorySelectedData(storedSelectedData);



   //   },
   //      []);

   useEffect(() => {
      // Update localStorage when selectedData changes
      if (catagorySelectedData.length && catagorySelectedData[0].price) {
         //   localStorage.setItem('price', catagorySelectedData[0].price);
         setgProductForm({
            ...gProductForm,
            price: catagorySelectedData[0].price,

         })

      }
   }, [catagorySelectedData]);

   //   const fetchDataById = (p_id) => {
   //      console.log("Fetching data for p_id:", p_id); // Debugging log
   //    //   localStorage.setItem("p_id", p_id);
   //    setgProductForm({
   //       ...gProductForm,
   //      p_id:p_id,

   //    })



   //      const formData = new FormData();
   //      formData.append('p_id', p_id);

   //      fetch('https://shreddersbay.com/API/product_api.php?action=select_id',
   //         {
   //            method: 'POST',
   //            body: formData,
   //         })


   //         .then((response) => {
   //            console.log('Response:', response); // Debugging log

   //            if (!response.ok) {
   //               throw new Error('Network response was not ok');
   //            }
   //            return response.json();
   //         })
   //         .then((data) => {
   //            console.log("Fetched data by p_id:", data); // Debugging log
   //            if (!data) {
   //               console.error('No data returned from API'); // Debugging log

   //            } else {
   //               // setChooseSubcatagory(data.length && data[0].p_type_name)
   //             //   setSelectedData(data);
   //               setcatagorySelectedData(data);
   //             //   localStorage.setItem("selectedData", JSON.stringify(data));
   //               console.log("Selected data set:", data); // Debugging log

   //            }
   //         })
   //         .catch((error) => {
   //            console.error('Error fetching data by p_id:', error);
   //         });
   //   };

   const handleChangeTitle = (event) => {
      const inputValue = event.target.value;
      setTitle(inputValue);
      //  localStorage.setItem('title', inputValue);
      setgProductForm({
         ...gProductForm,
         title: inputValue,

      })


      if (inputValue.length >= 8) {
         setError(false);
         sethelperText('');
      }
      else {
         setError(true);
         sethelperText('Must be exactly 8 characters');
      }

   }

   const handleBackClick = () => {
      navigate('/sell');
   };

   const handleChangeDesc = (event) => {
      const inputValue = event.target.value;
      setDesc(inputValue);
      //  localStorage.setItem('description', inputValue);
      setgProductForm({
         ...gProductForm,

         description: inputValue,
      })

      if (inputValue.length >= 10) {
         setdescError(false);
         setdeschelperText('');
      }
      else {
         setdescError(true);
         setdeschelperText('Must be exactly 10 characters');
      }

   }

   const handleChangePrice = (event) => {
      const inputValue = event.target.value;
      setPrice(inputValue);
      //  localStorage.setItem("price", inputValue)
      setgProductForm({
         ...gProductForm,
         price1: inputValue,
      })

      if (!isNaN(inputValue)) {
         setpriceError(false);
         setpricehelperText('');
      }
      else {
         setPrice('');
         setpriceError(true);
         setpricehelperText('Please enter a valid numeric value');
      }


   }

   const handleChangeWeight = (event) => {
      const inputValue = event.target.value;
      setWeight(inputValue);
      //  localStorage.setItem("weight", inputValue)
      setgProductForm({
         ...gProductForm,
         weight: inputValue,
      })

      if (!isNaN(inputValue)) {
         setweightError(false);
         setweighthelperText('');
      }
      else {
         setWeight('');
         setweightError(true);
         setweighthelperText('Please enter a valid numeric value');
      }


   }
   const isUserExit = () => {
      // Check if any of the user_email, user_id, or user_name are either empty strings or null
      if (!user_email && !user_id && !user_name) {
        return false;
      } else {
        return true;
      }
    };
    

   const handleNext = () => {
      console.log("Local Replaced data=>", gProductForm);
      if (isUserExit()) {
         if (title.length < 8) {
            setError(true);
            sethelperText('Must be exactly 8 characters');
         }
         else if (desc.length < 10) {
            setdescError(true);
            setdeschelperText('Must be exactly 10 characters');
         }
         else if (isNaN(price) || price === "") {
            setPrice('');
            setpriceError(true);
            setpricehelperText('Please enter a valid numeric value');
         }
         else if (isNaN(Weight) || Weight === "") {
            setWeight('');
            setweightError(true);
            setweighthelperText('Please enter a valid numeric value');
         }
         else {

            // history.push('/multiplephoto');
            navigate('/multiplephoto');
         }
      } else {
         setIsUserAuthEnable(!isUserAuthEnable);
      }

   }


   return (
      <Box sx={{
         marginTop: {xs:8 , sm: 0, md: 0 }
      }}>

         <Box
            sx=
            {{
               display: 'flex',
               alignItems: 'center',
               padding: '10px',
               backgroundColor: '#eee',
               boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }}
         >
            <ArrowBackIcon
               sx={{ cursor: 'pointer', marginLeft: '20px' }}
               onClick={handleBackClick}
            />

         </Box>

         <h4 className='ads' style={{ color: '#00457E', fontWeight: '800' , margin: 20}}>POST YOUR ADS</h4>

         <Box sx=
            {{
               border: '1px solid black',
               width: { xs: '100%', md: '70%' },
               marginTop: { xs: '2%', md: '2%' }, // Removes margin on mobile (xs) and keeps it for medium screens (md) and up
               padding: '3%',
               marginRight: { md: 'auto' },
               marginLeft: { md: 'auto' },
            }}>

            <Box className='CatText'
               sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 3, marginBottom: 3 }}
            >
               <h5>Choose a Category</h5>
            </Box>

            <UserAuth openAuth={isUserAuthEnable} setOpenAuth={setIsUserAuthEnable} />
            <Grid item xs={6} md={6}>

               {catagorySelectedData && (
                  <Box
                     component="form"
                     sx={{ '& > :not(style)': { m: 1, width: '100%' } }}
                     noValidate
                     autoComplete="off"
                  >
                     <TextField
                        id="standard-basic"
                        value={catagorySelectedData.length && catagorySelectedData[0].p_type_name}
                        variant="standard"
                     />
                     <TextField
                        id="standard-basic"
                        variant="standard"
                        value={`Minimum set Price: ${catagorySelectedData.length && catagorySelectedData[0].price}`}
                     />
                     <TextField
                        id="standard-basic"
                        label="Title *"
                        variant="standard"
                        onChange={handleChangeTitle}
                        error={!!error}
                        helperText={helperText}
                     />
                     <TextField
                        id="standard-basic"
                        label="Description *"
                        variant="standard"
                        onChange={handleChangeDesc}
                        error={!!descerror}
                        helperText={deschelperText}
                     />
                     <TextField
                        id="standard-basic"
                        label="Enter Price *"
                        variant="standard"
                        onChange={handleChangePrice}
                        error={priceerror}
                        helperText={pricehelperText}
                     />
                     <TextField
                        id="standard-basic"
                        label="Enter Weight(kg) *"
                        variant="standard"
                        onChange={handleChangeWeight}
                        error={weighterror}
                        helperText={weighthelperText}
                     />
                  </Box>
               )}

               <Box sx={{ marginTop: '5%' }}>
                  <AwesomeButton
                     onPress={handleNext}
                     className="animatedButton"
                     type="teal"
                     style={{ color: '#fff' }}
                  >
                     Next
                  </AwesomeButton>
               </Box>

            </Grid>
         </Box>

      </Box>
   )
}

export default ProductForm