
import React, { useEffect, useState,useContext } from 'react';

import { useLocation } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
// import { ImagesApp } from '../../Images/ImagesApp';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// import { useNavigate } from 'react-router-dom';
const imgurl = "https://shreddersbay.com/API/uploads/";


function AuctionOrderdetail(auctionId) {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));

  const navigate = useNavigate();


  
  const location = useLocation();
  const { orderData, allimg } = location.state || {}; // Ensure this retrieves both orderData and allimg
  
  console.log("all imgs =:-", allimg); // This should log an array of image filenames
  
  if (!orderData || !allimg) {
    return <p>No order data available.</p>; // Handle the case when data isn't available
  }
  
  const handleBackClick = () => {
    navigate('/');
 };

  const redTextStyle = {
    color: 'red'
  };

  return (



    <Box>

<Box
                    sx=
                    {{
                         display: 'flex',
                         alignItems: 'center',
                         padding: '10px',
                         backgroundColor: '#eee',
                         marginTop:{xs:8, md:5},
                         boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
               >
                    <ArrowBackIcon
                         sx={{ cursor: 'pointer', marginLeft: '20px' }}
                         onClick={handleBackClick}
                    />

               </Box>
      {/* <img src={ImagesApp.banner} width={"100%"} /> */}
       <Box  className='main-slide'>

         <Carousel
             
              autoPlay={true}
              interval={3000} // 3 seconds interval
              infiniteLoop={true} // Loop the slides infinitely
              showThumbs={false} // Hide the thumbnails
              showStatus={false} // Hide the status
              dynamicHeight={true} // Adjusts height based on content
              stopOnHover={true} // Stops autoplay on hover
         >

              {allimg.map((filename,index) => (
                   <div key={index} 
                   style=
                   {{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        height: '400px', 
                        backgroundColor: 'black',
                        }}
                        >
                        <img
                             src={`${imgurl}${filename}`}
                             alt={`Slide ${index + 1}`}
                             style={{
                                  maxHeight: '100%',
                                  maxWidth: '100%',
                                  objectFit: 'contain',
                                  borderRadius: '10px' // Adds rounded corners
                             }}
                        />
                   </div>
              ))}

         </Carousel>



    </Box>

      <Box sx={{ margin: 0 }}>

        <Box sx={{
          backgroundColor: '#ebf1ff',
          padding: { xs: 0, sm: 6 },
        }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Item>

                <Box
                  sx={{
                    padding: 2,
                    border: 1,
                    borderColor: '#eee',
                    fontSize: 20,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                      <strong>Auction Detail:</strong>
                    </Grid>

                  </Grid>
                </Box>

                <Box sx=
                  {{
                    padding: { xs: 0, sm: 3 },
                    marginTop: 1,
                    border: 1,
                    borderColor: '#eee',
                    textAlign: 'left',
                  }}
                >

                  <Grid container spacing={2}>
                    <Grid item xs={6} style={{ textAlign: 'left' }}>
                      <Box sx={{ padding: 2 }}>Product_Name:</Box>
                      <Box sx={{ padding: 2 }}>Booking_ID:</Box>
                      <Box sx={{ padding: 2 }}>Address::</Box>
                      <Box sx={{ padding: 2 }}>Approximate_Price:</Box>
                      <Box sx={{ padding: 2 }}>Booking_Date::</Box>

                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>

                      {/*check 
                       */}
                      {/* <p>{JSON.stringify(orderData)}</p> */}
                      <Box sx={{ padding: 2, color: 'red' }}>{orderData ? orderData.p_name : ""}</Box>
                      <Box sx={{ padding: 2, color: 'red' }} >{orderData ? orderData.bid_id : '--'}</Box>
                    
                        <Box sx={{ padding: 2, color: 'red' }}>
                          {orderData ? orderData.addr_id : '--'}
                        </Box>

                      <Box sx={{ padding: 2, color: 'red' }}>{orderData ? orderData.total_price : '--'}</Box>
                      <Box sx={{ padding: 2, color: 'red' }}>{orderData ? orderData.booking_date : '--'}</Box>
                      
                      {/*  */}
                    </Grid>
                  </Grid>


                </Box>

              </Item>
            </Grid>





          </Grid>


        </Box>



      </Box>
    </Box>
   
  )
}

export default AuctionOrderdetail     