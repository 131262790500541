import React, { useState, useEffect, useContext } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomModalSignup from '../Home/CustomModalSignup';
import CustomModalLogin from '../Home/CustomModalSignin';
import { ImagesApp } from '../../Images/ImagesApp';
import { Context } from '../../Utils/ContextAPI/ContextProvider';

function UserAuth({ openAuth, setOpenAuth }) {
    const [gState, setGState, LogOut] = useContext(Context);
    const { user_name, user_id, user_email } = gState
    const [openSignUp, setopenSignUp] = useState(false);
    const [openLogin, setOpenLogin] = useState(openAuth);
    const [name, setName] = useState('');
    const [emails, setEmails] = useState('');
    const [phone, setPhone] = useState('');
    const [Passwords, setPasswords] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (openAuth) {
            setOpenLogin(true);
        }
    }, [openAuth]);

    const handleSignUp = async () => {
        if (Passwords !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', emails);
        formData.append('mobile', phone);
        formData.append('password', Passwords);

        try {
            const response = await fetch('https://shreddersbay.com/API/user_api.php?action=signup', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();

            if (result.message !== "Email Already Exist") {
                alert(`${result.message}`);
                setName("");
                setEmails("");
                setPasswords("");
                setConfirmPassword("");
                setPhone("");
                handleCloseSignup();
            } else {
                alert(result.message || 'Signup failed');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred during signup');
        }
    };

    const handleLogin = async () => {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);

        try {
            const response = await fetch('https://shreddersbay.com/API/user_api.php?action=signin', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();
            console.log("Result for success=>", result);

            if (result.message === "Login Successfully") {
                localStorage.setItem("@u_name", result["0"].name);
                localStorage.setItem("@u_id", result["0"].id);
                localStorage.setItem("@u_email", result["0"].email);
                setGState({
                    ...gState,
                    user_email: result["0"].email,
                    user_id: result["0"].id,
                    user_name: result["0"].name,
                    firebase_uid:result["0"].firebase_uid,
                })
                setOpenLogin(false);
                setOpenAuth(false);

                setEmail("");
                setPassword("");

                alert(result.message);
            } else {
                console.log("login failed");
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred during login');
        }
    };

    const handleOpenSignin = () => {
        setOpenLogin(true);
        setopenSignUp(false);
    };

    const handleCloseSignup = () => {
        setopenSignUp(false);
    };

    const handleOpenSignup = () => {
        setopenSignUp(true);
        setOpenLogin(false);
    };

    const handleCloseLogin = () => {
        setOpenLogin(false);
        setOpenAuth(false);
    };

    const handleModalClose = () => {
        setOpenAuth(false);
        setOpenLogin(false);
        setopenSignUp(false);
    };

    return (
        <Box>
            <CustomModalSignup
                open={openSignUp}
                handleClose={handleModalClose}
                handleSignUp={handleSignUp}
                handleOpenSignin={handleOpenSignin}
                images={ImagesApp.signupshreds}
                name={name}
                setName={setName}
                email={emails}
                setEmail={setEmails}
                password={Passwords}
                setPassword={setPasswords}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                phone={phone}
                setPhone={setPhone}
            />
            <CustomModalLogin
                open={openLogin}
                handleClose={handleModalClose}
                handleLogin={handleLogin}
                handleOpenSignup={handleOpenSignup}
                email={email}
                setEmail={setEmail}
                password={password}
                handleonchangepassword={(e) => setPassword(e.target.value)}
                ImagesApp={ImagesApp}
            />
            {/* <IconButton
                style={{ position: 'absolute', top: '10px', right: '10px' }}
                onClick={handleModalClose}
            >
                <CloseIcon />
            </IconButton> */}
        </Box>
    );
}

export default UserAuth;
