import React , { useEffect } from 'react'
import "../Home/Category.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ImagesApp } from '../../Images/ImagesApp';



const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};


function Category() {

  useEffect(() => {
    const sparkleContainer = document.querySelector('.sparkle-container');
    
    if (sparkleContainer) {
      for (let i = 0; i < 10; i++) {
        const sparkle = document.createElement('div');
        sparkle.className = 'sparkle';
        sparkle.style.top = `${Math.random() * 50}%`;
        sparkle.style.left = `${Math.random() * 100}%`;
  
        // Random color generation
        const randomColor = `hsl(${Math.floor(Math.random() * 360)}, 100%, 75%)`; // Bright pastel color
        sparkle.style.backgroundColor = randomColor;
  
        sparkleContainer.appendChild(sparkle);
        console.log('Sparkle created:', sparkle); // Check this in console
      }
    }
  }, []);
  
  
  return (

    <div className='app'>
    
    <div className="sparkle-container">
        {/* Sparkles will appear here */}
      </div>


      <Carousel
        responsive={responsive}
        autoPlay={true}        // Enable auto-slide
        autoPlaySpeed={1000}   // Set the interval time (3000ms = 3 seconds)
        infinite={true}        // Enable infinite looping
        className='container'>
        <div >
          <img src={ImagesApp.Steel} className='product--image  '></img>
          <h2 className='product_text '>Steel</h2>

        </div>




        <div>
          <img src={ImagesApp.Aluminium} className='product--image'></img>
          <h2 className='product_text'>Aluminium</h2>
        </div>


        <div  >
          <img src={ImagesApp.Bike} className='product--image'></img>
          <h2 className='product_text'>Bike</h2>
        </div>


        <div  >
          <img src={ImagesApp.Brass2} className='product--image'></img>
          <h2 className='product_text'>Brass</h2>
        </div>

        <div >
          <img src={ImagesApp.cable} className='product--image'></img>
          <h2 className='product_text'>Cable</h2>
        </div>
        <div >
          <img src={ImagesApp.car} className='product--image'></img>
          <h2 className='product_text'>Car</h2>
        </div>
        <div >
          <img src={ImagesApp.computer} className='product--image'></img>
          <h2 className='product_text'>Computer</h2>
        </div>
        <div >
          <img src={ImagesApp.cycle} className='product--image' ></img>
          <h2 className='product_text'>Cycle</h2>
        </div>
        <div >
          <img src={ImagesApp.iron} className='product--image' ></img>
          <h2 className='product_text'>Iron</h2>
        </div>
        <div >
          <img src={ImagesApp.lead1} className='product--image' ></img>
          <h2 className='product_text'>Lead</h2>
        </div>
        <div >
          <img src={ImagesApp.newpaper} className='product--image' ></img>
          <h2 className='product_text'>Newspaper</h2>
        </div>
        <div >
          <img src={ImagesApp.plastic} className='product--image' ></img>
          <h2 className='product_text'>plastic</h2>
        </div>
        <div >
          <img src={ImagesApp.pump} className='product--image' ></img>
          <h2 className='product_text'>Pump</h2>
        </div>
        <div >
          <img src={ImagesApp.railway} className='product--image' ></img>
          <h2 className='product_text'>Railway</h2>
        </div>
        <div >
          <img src={ImagesApp.ship} className='product--image' ></img>
          <h2 className='product_text'>Ship</h2>
        </div>


      </Carousel>

      <div className="sparkle-container">
        {/* Sparkles will appear here */}
      </div>
    </div>

  )
}

export default Category