import React, { useEffect, useState, useContext } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { Button } from '@mui/material';
import { app } from '../../../Utils/fire_base/firebase_setup';
import { Context } from '../../../Utils/ContextAPI/ContextProvider';
import { json } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleIcon from '@mui/icons-material/Google';
import { create_user_in_firebase, get_user_data } from '../../../Constants/Firebase_functions';

const auth = getAuth();
const provider = new GoogleAuthProvider();

function GWebbtn({ handleClose }) {
    const [user, setUser] = useState(null);
    const [gState, setGState] = useContext(Context)
    const [value, setValue] = useState('');
    const [firebaseuid, setFirebaseuid] = useState("");

    const handleClick = () => {
        // console.log("google btn is clicked in phone");
        signInWithPopup(auth, provider)
            .then((data) => {
                setValue(data.user.email);
                // console.log("data=>>", data);
                setFirebaseuid(data.user.uid);
                // console.log("firebasesuid is =>", data.user.uid);
                sendGoogleUserDataToServer(data.user.uid, data.user.accessToken, data.user.displayName, data.user.email, data.user.photoURL, setGState, gState, data.user.uid)
            })
            .catch((error) => {
                console.error('Error during Google sign-in:', error);
            });
    };

    //  const sendGoogleUserDataToServer=async(gUserId, gAccessToken, gUserName, gUserEmail, gUserPic, setGState, gState, firebaseID)=> {
    //      if (gUserId !== "" && gAccessToken !== "" && gUserName !== "" && gUserEmail !== "" && gUserPic !== "" && firebaseID !== "") {
    //           console.log("firebase Uid is =>>", firebaseID);
    //           console.log("firebase guserID is =>>", gUserId);
    //           console.log("firebase gAccessToken is =>>", gAccessToken);
    //           console.log("firebase gUserName is =>>", gUserName);
    //           console.log("firebase gUserEmail is =>>", gUserEmail);
    //           console.log("firebase guserPic is =>>", gUserPic);
    //           try {
    //                // Prepare FormData object
    //                const formData = new FormData();
    //                formData.append('google_id', gUserId);
    //                formData.append('token', gAccessToken);
    //                formData.append('name', gUserName);
    //                formData.append('email', gUserEmail);
    //                formData.append('profile_pic', gUserPic);
    //                formData.append('firebase_uid', firebaseID);
    //                console.log("foremdata=>",formData);

    //                // Optionally, log FormData entries
    //                for (let pair of formData.entries()) {
    //                     console.log(`${pair[0]}: ${pair[1]}`);
    //                }

    //                // Send FormData to the server
    //                const res = await fetch("https://shreddersbay.com/API/user_api.php?action=google_login", {
    //                     method: 'POST',
    //                     body: formData,
    //                });

    //                // Handle server response
    //                console.log("Google response=::::>", res);
    //                if (res.ok) {
    //                     const data = await res.json();
    //                     localStorage.setItem("@u_name", data["0"].name);
    //                     localStorage.setItem("@u_id", data["0"].id);
    //                     localStorage.setItem("@u_email", data["0"].email);
    //                     localStorage.setItem("@fuid", data["0"].firebase_uid);

    //                     // Update the global state using setGState
    //                     setGState({
    //                          ...gState,
    //                          user_name: data["0"].name,
    //                          user_email: data["0"].email,
    //                          user_id: data["0"].id,
    //                          firebase_uid: data["0"].firebase_uid,
    //                     });
    //                     console.log("api google data=>",JSON.stringify(data));
    //                     alert(`Data: ${JSON.stringify(data)}`);
    //                     handleClose();
    //                } else {
    //                     console.error('Failed to send data to server');
    //                }
    //           } catch (error) {
    //                console.error('Error:', error);
    //           }
    //      } else {
    //           alert("please feed all filed..............")
    //      }
    // }
    const sendGoogleUserDataToServer = async (gUserId, gAccessToken, gUserName, gUserEmail, gUserPic, setGState, gState, firebaseID) => {
        get_user_data(firebaseID).then((data) => {
            if (data) {
                alreadyExits_firelogin_and_web(gUserId, gAccessToken, gUserName, gUserEmail, gUserPic, firebaseID);
            } else {
                firelogin_and_web(gUserId, gAccessToken, gUserName, gUserEmail, gUserPic, firebaseID);

            }
        }).catch((data) => {
            console.log("failed to set data in firebase Gwebbtn=>", data)
        })
    };
    const alreadyExits_firelogin_and_web = async (gUserId, gAccessToken, gUserName, gUserEmail, gUserPic, firebaseID) => {
        if (gUserId && gAccessToken && gUserName && gUserEmail && gUserPic && firebaseID) {
            // console.log("Firebase UID is =>>", firebaseID);  // Check firebaseID before appending
            try {
                // Prepare FormData object
                const formData = new FormData();
                formData.append('google_id', gUserId);
                formData.append('token', gAccessToken);
                formData.append('name', gUserName);
                formData.append('email', gUserEmail);
                formData.append('profile_pic', gUserPic);
                formData.append('firebase_uid', firebaseID);
                console.log("FormData entries:");
                for (let pair of formData.entries()) {
                    console.log(`${pair[0]}: ${pair[1]}`);  // Log all FormData entries
                }
                // Send FormData to the server
                const res = await fetch("https://shreddersbay.com/API/user_api.php?action=google_login", {
                    method: 'POST',
                    body: formData,
                });
                // Handle server response
                // console.log("Google response=::::>", res);
                if (res.ok) {
                    const data = await res.json();
                    localStorage.setItem("@u_name", data["0"].name);
                    localStorage.setItem("@u_id", data["0"].id);
                    localStorage.setItem("@u_email", data["0"].email);
                    localStorage.setItem("@fuid", data["0"].firebase_uid);
                    // Update the global state using setGState
                    setGState({
                        ...gState,
                        user_name: data["0"].name,
                        user_email: data["0"].email,
                        user_id: data["0"].id,
                        firebase_uid: data["0"].firebase_uid,
                    });
                    // create_user_in_firebase(data['0'].firebase_uid, data["0"].email, data["0"].name, data["0"].mobile);

                    // console.log("API Google data=>", JSON.stringify(data));
                    // alert(`Data: ${JSON.stringify(data)}`);
                    handleClose();
                } else {
                    console.error('Failed to send data to server');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            alert("Please fill all fields");
            console.error("Missing fields:", { gUserId, gAccessToken, gUserName, gUserEmail, gUserPic, firebaseID });
        }
    }
    const firelogin_and_web = async (gUserId, gAccessToken, gUserName, gUserEmail, gUserPic, firebaseID) => {
        if (gUserId && gAccessToken && gUserName && gUserEmail && gUserPic && firebaseID) {
            // console.log("Firebase UID is =>>", firebaseID);  // Check firebaseID before appending
            try {
                // Prepare FormData object
                const formData = new FormData();
                formData.append('google_id', gUserId);
                formData.append('token', gAccessToken);
                formData.append('name', gUserName);
                formData.append('email', gUserEmail);
                formData.append('profile_pic', gUserPic);
                formData.append('firebase_uid', firebaseID);
                console.log("FormData entries:");
                for (let pair of formData.entries()) {
                    console.log(`${pair[0]}: ${pair[1]}`);  // Log all FormData entries
                }
                // Send FormData to the server
                const res = await fetch("https://shreddersbay.com/API/user_api.php?action=google_login", {
                    method: 'POST',
                    body: formData,
                });
                // Handle server response
                // console.log("Google response=::::>", res);
                if (res.ok) {
                    const data = await res.json();
                    localStorage.setItem("@u_name", data["0"].name);
                    localStorage.setItem("@u_id", data["0"].id);
                    localStorage.setItem("@u_email", data["0"].email);
                    localStorage.setItem("@fuid", data["0"].firebase_uid);
                    // Update the global state using setGState
                    setGState({
                        ...gState,
                        user_name: data["0"].name,
                        user_email: data["0"].email,
                        user_id: data["0"].id,
                        firebase_uid: data["0"].firebase_uid,
                    });
                    create_user_in_firebase(data['0'].firebase_uid, data["0"].email, data["0"].name, data["0"].mobile);

                    // console.log("API Google data=>", JSON.stringify(data));
                    // alert(`Data: ${JSON.stringify(data)}`);
                    handleClose();
                } else {
                    console.error('Failed to send data to server');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            alert("Please fill all fields");
            console.error("Missing fields:", { gUserId, gAccessToken, gUserName, gUserEmail, gUserPic, firebaseID });
        }
    }

    // useEffect(() => {
    //      const storedEmail = localStorage.getItem('email');
    //      if (storedEmail) {
    //           setValue(storedEmail);
    //      }
    // }, []); // Dependency array added to prevent infinite re-renders

    return (
        <div>
            {/* <h1>{value || 'Please sign in'}</h1>
      <h1>{firebaseuid|| " firebase id"}</h1> */}
            <Button variant="contained" sx={{backgroundColor: '#00457E'}} onClick={handleClick}>
            <GoogleIcon color='#fff'/>   
            </Button>
        </div>
    );
}

export default GWebbtn;
