

import React, { createContext, useEffect, useState } from 'react';

// Create the context
const Context = createContext();

function ContextProvider(props) {
    const [gState, setGState] = useState({
        user_name: "",
        user_id: "",
        user_email: "",
        firebase_uid: "",
    });
    // const [gCatagory, setgCatagory] = useState([]);

    const [gProductForm, setgProductForm] = useState({
        title: "",
        description: "",
        price: "",
        weight: "",
        p_id:"",
        price1:"",
        photo:"",
        firebase_pid:"",
       


    });
    const [catagorySelectedData, setcatagorySelectedData] = useState([]);
    
const [chatGState, setChatGState] = useState({
    bfirebaseUID:"",
    bfirebaseProdID:"",
    conversationID:"",
})

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        const uname = localStorage.getItem("@u_name");
        const uId = localStorage.getItem("@u_id");
        const uEmail = localStorage.getItem("@u_email");
        const fUID = localStorage.getItem("@fuid");
        console.log("uname is null localstoage=>",uname);
        console.log("UID is null localstoage=>",uId);
        console.log("uEmail is null localstoage=>",uEmail);
        console.log("FUID is null localstoage=>",fUID);


        setGState({
            user_name: uname ,
            user_id: uId ,
            user_email: uEmail,
            firebase_uid:fUID,
        });
    };

    const LogOut = () => {
        setGState({
            user_name: "",
            user_id: "",
            user_email: "",
            firebase_uid:"",
        });
        localStorage.removeItem("@u_name");
        localStorage.removeItem("@u_id");
        localStorage.removeItem("@u_email");
        localStorage.removeItem("udata");
        localStorage.removeItem("utoker");
        localStorage.removeItem("user");
        localStorage.removeItem("udata1");
        localStorage.removeItem("p_id");
        localStorage.removeItem("selectedData");
        localStorage.removeItem("weight");
        localStorage.removeItem("title");
        localStorage.removeItem("image");
        localStorage.removeItem("description");
        localStorage.removeItem("price");
        localStorage.removeItem("images");
        localStorage.removeItem("@firebase_uid");
        localStorage.removeItem("@fuid");
        localStorage.removeItem("email");


        


    };

   

    return (
        <Context.Provider value={[gState, 
            setGState,
             LogOut, 
             gProductForm, 
             setgProductForm,
             catagorySelectedData,
             setcatagorySelectedData,
             chatGState,
             setChatGState
        ]}>
            {props.children}
        </Context.Provider>
    );
}

export {
    ContextProvider,
    Context
};
