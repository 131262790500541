
import React, { useEffect, useState, useContext } from 'react';
import { Box, Grid, Radio, RadioGroup, FormControlLabel, FormLabel, } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Context } from '../../Utils/ContextAPI/ContextProvider.js';
import { AwesomeButton } from 'react-awesome-button';
import { Color } from '../../Constants/Color.js';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Select,
  MenuItem as DropdownItem,
  FormControl,
  InputLabel,
  Menu
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';



function MyAddress() {
  const [gState] = useContext(Context);
  const { user_id } = gState;
  const navigate = useNavigate();

  const [addresses, setAddresses] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedAddress, setEditedAddress] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [toggle, setToggle] = useState(false);
  const [road, setRoad] = useState('');
  const [pincode, setPincode] = useState('');
  const [landmark, setLandmark] = useState('');
  const [houseNo, setHouseNo] = useState('');
  const [savedAddress, setsavedAddress] = useState([]);
  const [address, setAddress] = useState("");
  const [filterstate, setFilterState] = useState([]);
  const [filtercity, setFilterCity] = useState([]);
  const [filteraddress, setFilterAddress] = useState([]);


  const handleToggle = () => {
    handleResetData();
    fetchAddress();
    setToggle(!toggle);
  }

  useEffect(() => {
    if (country) {
      const fetchAddresses = async () => {
        try {
          const response = await fetch(`https://shreddersbay.com/API/area_api.php?action=select&city_id=2`);
          const data = await response.json();
          console.log("address data", data);
          setAddresses(data);
        } catch (error) {
          console.log('error fetching addresses:', error);
        }
      };
      fetchAddresses();
    } else {
      setAddresses([]);
    }
  }, [country]);




  const handleResetData = () => {
    setCountry("");
    setState("");
    setCity("");
    setHouseNo("");
    setRoad("");
    setPincode("");
    setLandmark("");
    setAddress("");
  }

  const handleChangeCountry = (event) => {
    const selectedCountryId = event.target.value;
    setCountry(selectedCountryId);
    setState(''); // Reset state when country changes
    setCity(''); // Reset city when country changes
    setAddress('');
    setFilterState([]); // Reset filtered states
    setFilterCity([]); // Reset filtered cities
    setFilterAddress([]);

  };

  const handleChangeState = (event) => {
    const selectedStateId = event.target.value;
    setState(selectedStateId);

    // const filteredCities = cities.filter(city => city.state_id === selectedStateId);
    setFilterCity([]);
    setCity(''); // Reset city when state changes
  };

  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };


  const handleChangeAddress = (event) => {
    setAddress(event.target.value);
  };


  const handleSaveAddress = async () => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append('country_id', country);
    formData.append('state_id', state);
    formData.append('city_id', city);
    formData.append('houseno', houseNo);
    formData.append('road', road);
    formData.append('pincode', pincode);
    formData.append('landmark', landmark);
    formData.append('area_id', address);
    formData.append('address', `${houseNo},${road}`);



    console.log(`FormDAta ::>> userid : ${user_id}, country :${country}, state:${state},city :${city}, houseno:${houseNo}, road :${road}, pincode :${pincode}, landmar:${landmark}, address:${address}`);
    try {

      const response = await fetch('https://shreddersbay.com/API/address_api.php?action=insert', {

        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        const result = await response.json();
        console.log("address saved successfully", result);
        // close add address
        handleToggle();


      }
      else {
        console.log("failed to save address", response.statusText);
      }
    }
    catch (error) {
      console.log("error saving address", error);
    }
  };

  const handleChangeSelectedAddress = (event) => {
    const selectedId = event.target.value;
    console.log("Selected value:", selectedId); // Debugging: Log selected value
    setSelectedAddress(selectedId);

    const selectedAddressObject = savedAddress.find(address => address.id === selectedId);
    console.log("Selected address object:", selectedAddressObject); // Debugging: Log selected address object
  };

  // Fetch addresses by user ID
  const fetchAddress = async () => {
    setLoading(true);
    const url = `https://shreddersbay.com/API/address_api.php?action=AddrByUserId&user_id=${user_id}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      setAddresses(data); // Bind the response data to the addresses state
      setLoading(false);
    } catch (error) {
      setError("Error fetching addresses");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user_id) {
      fetchAddress();
    }
  }, [user_id]);

  // Fetch countries on component mount
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://shreddersbay.com/API/country_api.php?action=select');
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        setError('Error fetching countries');
      }
    };
    fetchCountries();
  }, []);

  // Fetch states based on selected country
  useEffect(() => {
    if (country) {
      const fetchStates = async () => {
        try {
          const response = await fetch(`https://shreddersbay.com/API/state_api.php?action=select&country_id=${country}`);
          const data = await response.json();
          setStates(data);
        } catch (error) {
          setError('Error fetching states');
        }
      };
      fetchStates();
    } else {
      setStates([]);
    }
  }, [country]);

  // Fetch cities based on selected state
  useEffect(() => {
    if (state) {
      const fetchCities = async () => {
        try {
          const response = await fetch(`https://shreddersbay.com/API/city_api.php?action=select&state_id=${state}`);
          const data = await response.json();
          setCities(data);
        } catch (error) {
          setError('Error fetching cities');
        }
      };
      fetchCities();
    } else {
      setCities([]);
    }
  }, [state]);

  // Handle opening the menu
  const handleMenuClick = (event, address) => {
    setAnchorEl(event.currentTarget);
    setSelectedAddress(address); // Set the selected address for delete/edit
  };

  // Handle closing the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle edit button click
  const handleEdit = () => {
    setEditedAddress(selectedAddress);
    setCountry(selectedAddress.country_id); // Set country for dropdown
    setState(selectedAddress.state_id); // Set state for dropdown
    setCity(selectedAddress.city_id); // Set city for dropdown
    setEditMode(true);  // Enable edit mode
    handleMenuClose(); // Close the menu
  };

  // Handle delete button click
  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this address?")) {
      const url = "https://shreddersbay.com/API/address_api.php?action=delete";
      const formData = new FormData();
      formData.append('addr_id', selectedAddress.addr_id);

      try {
        const response = await fetch(url, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          fetchAddress(); // Refresh addresses after deletion
        } else {
          console.error("Failed to delete address");
        }
      } catch (error) {
        console.error("Error deleting address:", error);
      }
    }
    handleMenuClose(); // Close the menu
  };

  // Handle form submission for address update
  const handleEditSubmit = async () => {
    const url = "https://shreddersbay.com/API/address_api.php?action=update";

    // Combining both arrays into one for FormData
    const dataArray1 = [
      { "key": "user_id", "value": user_id, "type": "text", "enabled": true },
      { "key": "country_id", "value": country, "type": "text", "enabled": true },
      { "key": "state_id", "value": state, "type": "text", "enabled": true },
      { "key": "city_id", "value": city, "type": "text", "enabled": true },
      { "key": "area_id", "value": "12", "type": "text", "enabled": true },  // Assuming area_id is constant
      { "key": "address", "value": editedAddress.address, "type": "text", "enabled": true },
      { "key": "landmark", "value": editedAddress.landmark, "type": "text", "enabled": true }
    ];

    const dataArray2 = [
      { "key": "pincode", "value": editedAddress.pin_code, "type": "text", "enabled": true },
      { "key": "addr_id", "value": editedAddress.addr_id, "type": "text", "enabled": true }
    ];

    const formData = new FormData();

    // Append data from both arrays to FormData
    [...dataArray1, ...dataArray2].forEach(item => {
      if (item.enabled) {
        formData.append(item.key, item.value);
      }
    });

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("API Response:", data);

        if (data.message && data.message.includes('Data updated')) {
          await fetchAddress();  // Fetch updated addresses after a successful update
          setEditMode(false);    // Disable edit mode after update
          setEditedAddress({});  // Clear the edited address state
        } else {
          console.error("Update failed:", data.message || "Unknown error");
        }
      } else {
        console.error("Failed to update address, status code:", response.status);
      }
    } catch (error) {
      console.error("Error during address update:", error);
    }
  };

  const handleBackClick = () => {
    navigate('/');
 };



  const handleInputChange = (e) => {
    setEditedAddress({
      ...editedAddress,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>

      
<Box
            sx=
            {{
               display: 'flex',
               alignItems: 'center',
               padding: '10px',
               backgroundColor: '#eee',
               marginTop:10,
               boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }}
         >
            <ArrowBackIcon
               sx={{ cursor: 'pointer', marginLeft: '20px' }}
               onClick={handleBackClick}
            />

         </Box>
      <Box sx={{
        marginTop: { xs: 10, sm: 0, md: 10 },  // Top margin for mobile (xs)
        marginLeft: { xs: 0, sm: 'auto', md: 20 },  // No left margin on mobile (xs)
        marginRight: { xs: 0, sm: 'auto', md: 20 }, // No right margin on mobile (xs)
    
         border: '1px solid lightgray',
        padding: '5%' }}>
        <h5 className='CatText'>Manage Address</h5>

        <Box sx={{ marginTop: "5%" }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleToggle()}
            style=
            {{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              backgroundColor: '#00457E',
              color: '#fff',
            }}
          >
            Add Address
          </Button>

          {
            toggle ?(
              <Box sx={{ minWidth: 200, marginTop: 5 }}>


              <FormControl fullWidth sx={{ marginTop: 3 }}>
                <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={country}
                  label="Select Country"
                  onChange={handleChangeCountry}
                >
                  {countries.map((country) => (
                    <MenuItem key={country.country_id} value={country.country_id}>
                      {country.country_name}
                    </MenuItem>
                  ))}
  
                </Select>
  
              </FormControl>
  
              <FormControl fullWidth sx={{ marginTop: 3 }}>
                <InputLabel id="demo-simple-select-label">Select State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={state}
                  label="Select State"
                  onChange={handleChangeState}
                >
                  {
                    states.map((state) => (
                      <MenuItem key={state.state_id} value={state.state_id}>
                        {state.state_name}
                      </MenuItem>
                    ))
                  }
  
  
                </Select>
              </FormControl>
  
              <FormControl fullWidth sx={{ marginTop: 3 }}>
                <InputLabel id="demo-simple-select-label">Select City</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={city}
                  label="Select City"
                  onChange={handleChangeCity}
                >
                  {cities.map((city) => (
                    <MenuItem key={city.city_id} value={city.city_id}>
                      {city.city_name}
                    </MenuItem>
                  ))
                  }
                </Select>
              </FormControl>
  
              <Box sx={{ marginTop: 2 }}>
                <TextField
                  id="outlined-basic"
                  label="House No, Building Name"
                  fullWidth variant="outlined"
                  sx={{ marginTop: 2 }}
                  value={houseNo}
                  onChange={(e) => setHouseNo(e.target.value)}
                />
  
  
                <TextField id="outlined-basic"
                  label="Road Name, Area, Colony"
                  fullWidth variant="outlined"
                  sx={{ marginTop: 2 }}
                  value={road}
                  onChange={(e) => setRoad(e.target.value)}
                />
  
  
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Pincode"
                      variant="outlined"
                      fullWidth
                      sx={{ marginTop: 2 }}
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Landmark"
                      variant="outlined"
                      fullWidth
                      sx={{ marginTop: 2 }}
                      value={landmark}
                      onChange={(e) => setLandmark(e.target.value)}
                    />
                  </Grid>
                </Grid>
  
  
  
              </Box>
  
              <FormControl fullWidth sx={{ marginTop: 3 }}>
                <InputLabel id="demo-simple-select-label">Type of address</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={address}
                  label="Address"
                  onChange={handleChangeAddress}
                >
                  {
                    addresses.map((address) => (
                      <MenuItem key={address.area_id} value={address.area_id}>
                        {address.area_name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
  
              <AwesomeButton
  
                className="animatedButtonSave"
                type='teal'
  
                style={{ color: Color.light.text }}
                onPress={handleSaveAddress}
              >
                Save Address
              </AwesomeButton>
  
              <AwesomeButton
  
                className="animatedButton1"
                type='teal'
                style={{ color: '#fff' }}
                onPress={handleResetData}
              >
                Reset
              </AwesomeButton>
  
  
            </Box>
            )
            :
            (
              <Box sx={{ marginTop: 5 }}>


              {loading && <p>Loading...</p>}
              {error && <p>{error}</p>}
              {addresses.length > 0 ? (
                addresses.map((address) => (
  
  
                  <Card key={address.addr_id} 
                  sx={{   
                    minWidth: { xs: 300, sm: 300, md: 500 },                                                                             minWidth: { xs: 300, sm: 300, md: 500 },
                }}>
                    <CardContent>
                      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  
  
                        {/* Add ml: 'auto' to push the IconButton to the right */}
                        <IconButton sx={{ ml: 'auto' }} onClick={(e) => handleMenuClick(e, address)}>
                          <MoreVertIcon />
                        </IconButton>
                      </Box>
  
                      <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
                        {address.address},{address.landmark}
                      </Typography>
  
                      <Typography variant="body2">
                        {address.city_name}, {address.state_name}, {address.country_name}
                      </Typography>
                      <Typography>
                        {address.pin_code}
                      </Typography>
                    </CardContent>
                  </Card>
  
                ))
              ) : (
                <p>No addresses found.</p>
              )}
  
              {/* Menu for Edit/Delete options */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <DropdownItem onClick={handleEdit}>Edit</DropdownItem>
                <DropdownItem onClick={handleDelete}>Delete</DropdownItem>
              </Menu>
  
              {/* Edit Address Modal */}
              <Dialog open={editMode} onClose={() => setEditMode(false)}>
                <DialogTitle>Edit Address</DialogTitle>
                <DialogContent>
                  <TextField
                    margin="dense"
                    label="Address"
                    name="address"
                    value={editedAddress.address || ''}
                    onChange={handleInputChange}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    label="Landmark"
                    name="landmark"
                    value={editedAddress.landmark || ''}
                    onChange={handleInputChange}
                    fullWidth
                  />
  
                  {/* Country Dropdown */}
                  <FormControl fullWidth margin="dense">
                    <InputLabel>Country</InputLabel>
                    <Select
                      value={country || ''}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      {countries.map((item) => (
                        <DropdownItem key={item.country_id} value={item.country_id}>
                          {item.country_name}
                        </DropdownItem>
                      ))}
                    </Select>
                  </FormControl>
  
                  {/* State Dropdown */}
                  <FormControl fullWidth margin="dense">
                    <InputLabel>State</InputLabel>
                    <Select
                      value={state || ''}
                      onChange={(e) => setState(e.target.value)}
                    >
                      {states.map((item) => (
                        <DropdownItem key={item.state_id} value={item.state_id}>
                          {item.state_name}
                        </DropdownItem>
                      ))}
                    </Select>
                  </FormControl>
  
                  {/* City Dropdown */}
                  <FormControl fullWidth margin="dense">
                    <InputLabel>City</InputLabel>
                    <Select
                      value={city || ''}
                      onChange={(e) => setCity(e.target.value)}
                    >
                      {cities.map((item) => (
                        <DropdownItem key={item.city_id} value={item.city_id}>
                          {item.city_name}
                        </DropdownItem>
                      ))}
                    </Select>
                  </FormControl>
  
                  <TextField
                    margin="dense"
                    label="Pincode"
                    name="pin_code"
                    value={editedAddress.pin_code || ''}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setEditMode(false)}>Cancel</Button>
                  <Button onClick={handleEditSubmit}>Save</Button>
                </DialogActions>
              </Dialog>
  
            </Box>
            )
          }


         

         





        </Box>
      </Box>



    </div>

  );
}

export default MyAddress;




