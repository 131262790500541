export const ctime = (index) => {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');

  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  const date = `${year}-${month}-${day}`;
  const time = `${hours}:${minutes}:${seconds}`;

  return `${date}${time}${index}`;
};

export const firstImg = (responseString) => {
  // Split the response string by commas
  const imagesArray = responseString.split(',');

  // Return the first element of the array
  return imagesArray.length > 0 ? imagesArray[0] : null;
};
export const isUserExits = (user_name, user_email, user_id) => {
  console.log("user_name=>", user_name);
  
  // Check if any of the values are null or empty
  if ((user_name === null || user_name === "") && 
      (user_email === null || user_email === "") && 
      (user_id === null || user_id === "")) {
    return false;
  } else {
    return true;
  }
};
