import React from 'react';

const MyIcon = () => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 1024 1024" // You can adjust this based on your SVG viewBox
    fillRule="evenodd"
    color='green'
  >
    <path
      d="M10 20 L30 40 L50 20 Z" // Replace this with the path data from your SVG
      fill='green' // Modify the color as needed
    />
  </svg>
);

export default MyIcon;
