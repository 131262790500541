import React, { useState, useEffect, useContext, useRef } from 'react';
import { faUser, faPhone, faEllipsisVertical, faXmark, faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { getDatabase, ref, set, onValue, get, update } from 'firebase/database';
import { generateUniqueId } from '../../Constants/Firebase_functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BiCheckDouble } from 'react-icons/bi';
import { Context } from '../../Utils/ContextAPI/ContextProvider';
import EmptyChatModal from './EmptyChatModal';
import { ImWhatsapp } from "react-icons/im";



const imgurl = "https://shreddersbay.com/API/uploads/";

function ChatRight() {
    const [gState, setGState, LogOut, gProductForm, setgProductForm, catagorySelectedData, setcatagorySelectedData, chatGState, setChatGState] = useContext(Context);
    const { firebase_uid } = gState;
    const { bfirebaseUID, bfirebaseProdID, conversationID } = chatGState;
    const phoneNumber = '917524090825'; // Format: 1234567890 with country code, no + or spaces
    const messagewatsapp = 'Hello! This is a test notification from our website.';

    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [productDetails, setProductDetails] = useState(null);

    // Create a ref for the chat message container and for last seen message
    const messagesEndRef = useRef(null);
    const lastSeenMessageRef = useRef(null);

    useEffect(() => {
        const db = getDatabase();

        if (chatGState.conversationID) {
            const fetchUserDetails = async () => {
                const userRef = ref(db, `users/${bfirebaseUID}`);
                try {
                    const snapshot = await get(userRef);
                    if (snapshot.exists()) {
                        setUserDetails(snapshot.val());
                    } else {
                        console.log("No user data available");
                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            };

            const fetchProductDetails = async () => {
                const productRef = ref(db, `product/${bfirebaseProdID}`);
                try {
                    const snapshot = await get(productRef);
                    if (snapshot.exists()) {
                        setProductDetails(snapshot.val());
                    } else {
                        console.log("No product data available");
                    }
                } catch (error) {
                    console.error("Error fetching product data:", error);
                }
            };

            const fetchMessages = async () => {
                const messagesRef = ref(db, `messages/${chatGState.conversationID}/all_messages`);
                onValue(messagesRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        const loadedMessages = Object.values(data)
                            .filter(msg => msg.content && msg.content.trim() !== "")
                            .filter((msg, index, self) =>
                                index === self.findIndex((m) => m.timestamp === msg.timestamp)
                            )
                            .sort((a, b) => a.timestamp - b.timestamp);

                        setMessages(loadedMessages);

                        loadedMessages.forEach((msg) => {
                            if (msg.receiverId === firebase_uid && msg.status === 'sent') {
                                updateMessageStatus(chatGState.conversationID, msg.messageId, 'delivered');
                            }
                        });
                    } else {
                        console.log("No messages available");
                    }
                });
            };

            fetchUserDetails();
            fetchProductDetails();
            fetchMessages();

            return () => {};
        } else {
            console.log("Conversation ID is missing, skipping data fetch.");
        }
    }, [chatGState.conversationID]);

    const sendMessage = async () => {
        if (message.trim() === "" || !chatGState.conversationID) return;

        const db = getDatabase();
        const messageId = generateUniqueId();

        const newMessage = {
            senderId: firebase_uid,
            receiverId: bfirebaseUID,
            productId: bfirebaseProdID,
            content: message,
            timestamp: Date.now(),
            status: 'sent',
            messageId,
        };

        try {
            const messageRef = ref(db, `messages/${conversationID}/all_messages/${messageId}`);
            await set(messageRef, newMessage);
            setMessage("");
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    function updateMessageStatus(conversationID, msgId, status) {
        const db = getDatabase();
        const messageRef = ref(db, `messages/${conversationID}/all_messages/${msgId}`);

        update(messageRef, { status })
            .then(() => {
                console.log(`Message ${msgId} status updated to ${status}`);
            })
            .catch((error) => {
                console.error("Error updating message status:", error);
            });
    }

    const sendWatsappMessage = () => {
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(messagewatsapp)}`;
        window.open(whatsappUrl, '_blank');
      };

    const handleScroll = () => {
        const chatMessagesDiv = document.querySelector('.chat-messages');
        if (chatMessagesDiv.scrollTop + chatMessagesDiv.clientHeight >= chatMessagesDiv.scrollHeight) {
            messages.forEach((msg) => {
                if (msg.receiverId === firebase_uid && msg.status === 'delivered') {
                    // updateMessageStatus(`${conversationID}`, msg.messageId, 'seen');
                }
            });
        }
    };

    useEffect(() => {
        const chatMessagesDiv = document.querySelector('.chat-messages');
        chatMessagesDiv.addEventListener('scroll', handleScroll);

        return () => {
            chatMessagesDiv.removeEventListener('scroll', handleScroll);
        };
    }, [messages, chatGState]);

    // Scroll to the bottom only for sender's message
    useEffect(() => {
        if (messages.length > 0 && messages[messages.length - 1].senderId === firebase_uid) {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    // Scroll to the last seen message on the receiver's side
    useEffect(() => {
        if (lastSeenMessageRef.current) {
            lastSeenMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleMessageChange = (e) => setMessage(e.target.value);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            sendMessage();
        }
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return `${date.getHours()}:${date.getMinutes()}`;
    };

    const handleDialPhone = () => {
        const phoneNumber = userDetails?.phone_number;
        if (phoneNumber) {
            window.location.href = `tel:${phoneNumber}`;
        } else {
            console.log("Phone number not available");
        }
    };

    const handlecloserightchatmodal=()=>{
        setChatGState({
            ...chatGState,
            conversationID:"",
        })
    }

        return (
            <div style={{ height: "80vh", display: 'flex', flexDirection: 'column' }}>
                <div>
                    
                </div>
                <div className="chat-header" style={{ display: 'flex', padding: 10, borderBottom: '2px solid gray', position: 'relative' }}>
                    <div className="chat-username" style={{ flex: 1, display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                        {productDetails && productDetails?.image ? (
                            <img
                                src={`${imgurl}${productDetails.image}`}
                                alt="Product"
                                style={{
                                    background: 'lightgray',
                                    border: '1px solid gray',
                                    padding: 10,
                                    borderRadius: '50%',
                                    marginRight: 10,
                                    height: "50px",
                                    width: "50px",
                                }}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faUser}
                                style={{
                                    background: 'lightgray',
                                    border: '1px solid gray',
                                    padding: 10,
                                    borderRadius: '50%',
                                    marginRight: 10,
                                }}
                            />
                        )}
                        <span>{userDetails?.username || ""}</span>
                    </div>
                    <div className="call" style={{ padding: 10 }} onClick={handleDialPhone}>
                        <FontAwesomeIcon icon={faPhone} />
                    </div>

                    <div className="call" style={{padding:10 }} onClick={handleDialPhone}>
                    <ImWhatsapp  size={20} variant="contained" onClick={sendWatsappMessage}/>              
                    </div>


                    <div className="block-user" style={{ padding: 10 }}>
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                    </div>
                    <div className="close-modal" style={{ padding: 10 }} onClick={handlecloserightchatmodal}>
                        <FontAwesomeIcon icon={faXmark} />
                    </div>
                </div>
    
                <div className="chat-messages" style={{ flex: 1, overflowY: 'auto', padding: 10, display: 'flex', flexDirection: 'column', height: '100vh' ,}}>
                    {messages.map((msg, index) => (
                        <div 
                          key={index} 
                          ref={msg.status === 'seen' ? lastSeenMessageRef : null}
                          className={`message ${msg.senderId === firebase_uid ? 'sender' : 'receiver'}`} 
                          style={{
                            display: 'flex', 
                            justifyContent: msg.senderId === firebase_uid ? 'flex-end' : 'flex-start', 
                            marginBottom: 20 ,
                        
                          }}>
                         
                            <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '70%',
        background: msg.senderId === firebase_uid ? '#dcf8c6' : 'white',
        padding: 10,
        borderRadius: 10,
        wordBreak: 'break-word',
        alignItems: 'flex-start' // Align items at the start to avoid vertical centering issues
      }}
    >
      <p
        style={{
          margin: 0,
          flex: 1, // Allow p tag to take up available space
        }}
      >
        {msg.content}
      </p>
    
      <div
        style={{
          display: 'flex',
          flexDirection: 'row', // Align items vertically
          justifyContent: 'space-between',
          alignItems: 'flex-end', // Align items to the end
          marginLeft: 10,
          marginTop:15,
           // Add some space between content and status
        }}
      >
        <span
          style={{
            fontSize: 10,
            color: 'gray',
            alignSelf: 'flex-end'
          }}
        >
          {formatTimestamp(msg.timestamp)}
        </span>
    
        {msg.senderId === firebase_uid && (
          <span
            style={{
              fontSize: 12,
              marginLeft: 5,
              display: 'flex',
              alignItems: 'center', // Center the check status vertically
            }}
          >
            {msg.status === 'sent' ? (
              <BiCheckDouble color="gray" />
            ) : msg.status === 'delivered' ? (
              <BiCheckDouble />
            ) : (
              <BiCheckDouble color="blue" />
            )}
          </span>
        )}
      </div>
    </div>
    
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                </div>
    
                <div className="chat-input" style={{ display: 'flex', alignItems: 'center', padding: 10, borderTop: '2px solid gray' }}>
                    <div className="paperclip" style={{ paddingRight: 10 }}>
                        <FontAwesomeIcon icon={faPaperclip} />
                    </div>
                    <input
                        value={message}
                        onChange={handleMessageChange}
                        onKeyDown={handleKeyDown}
                        style={{ flex: 1, padding: 10, borderRadius: 5, border: '1px solid gray' }}
                        placeholder="Write a message..."
                    />
                    <div className="send-button" style={{ paddingLeft: 10 }} onClick={sendMessage}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </div>
                </div>
            </div>
        );
    
}

export default ChatRight;


