import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import { ContextProvider } from './Utils/ContextAPI/ContextProvider';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>

    <ContextProvider>
      <App />
    </ContextProvider>
    
  </React.StrictMode>
);



reportWebVitals();

