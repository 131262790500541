
import React, { useEffect, useState, useContext } from 'react';
import { Box, Grid, Menu, Radio, RadioGroup, FormControlLabel, FormLabel, Card, Typography, CardContent } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import "../Sell/MultiplePhoto.css";
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
// import Select from 'react-select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { AwesomeButton } from 'react-awesome-button';
import { Color } from '../../Constants/Color';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { ctime, firstImg } from '../../Constants/Functions';
import { Context } from '../../Utils/ContextAPI/ContextProvider';
import VerifyModal from './VerifyModal';
import { create_product_in_firebase, generateUniqueId, get_user_data } from '../../Constants/Firebase_functions';



const base64ToBlob = (base64Data, contentType = '') => {
     const sliceSize = 512;
     const byteCharacters = atob(base64Data);
     const byteArrays = [];

     for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
               byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
     }

     return new Blob(byteArrays, { type: contentType });
};



function MultiplePhoto() {
     const [gState, setGState, LogOut, gProductForm, setgProductForm] = useContext(Context);
     const { user_id, user_name, user_email } = gState;
     const navigate = useNavigate();
     const [images, setImages] = useState([]);
     const [toggle, setToggle] = useState(false);


     const [country, setCountry] = useState("");
     const [countries, setCountries] = useState([]);


     const [state, setState] = useState("");
     const [states, setStates] = useState([]);
     const [filterstate, setFilterState] = useState([]);


     const [city, setCity] = useState("");
     const [cities, setCities] = useState([]);
     const [filtercity, setFilterCity] = useState([]);


     const [address, setAddress] = useState("");
     const [addresses, setAddresses] = useState([]);
     const [filteraddress, setFilterAddress] = useState([]);

     const [houseNo, setHouseNo] = useState('');
     const [road, setRoad] = useState('');
     const [pincode, setPincode] = useState('');
     const [landmark, setLandmark] = useState('');

     const [savedAddress, setsavedAddress] = useState([]);
     // const [selectedAddress, setselectedAddress] = useState('');
     const [selectedAddress, setSelectedAddress] = useState('');

     const [startDate, setStartDate] = useState(new Date());
     const setStartDate1 = (date) => {
          cartAdd(); setStartDate(date);
     }

     const maxBoxes = 10;

     const handleImageUpload = (event) => {
          console.log("newImage");
          const files = event.target.files;
          console.log("newImage", files);
          const newImages = [...images];
          console.log("newImage", newImages);

          for (let i = 0; i < files.length; i++) {
               const file = files[i];
               const reader = new FileReader();
               reader.onload = (e) => {
                    newImages.push(e.target.result);
                    // Set the state after all images are loaded
                    if (i === files.length - 1) {
                         setImages(newImages);
                    }
               };
               reader.readAsDataURL(file);
          }
     };

     // Remove image
     const removeImage = (index) => {
          const newImages = images.filter((_, i) => i !== index);
          setImages(newImages);
     };


     const handleBackClick = () => {
          navigate('/sell');
     };


     const handleToggle = () => {
          handleResetData();
          fetchAddress();
          setToggle(!toggle);
     }


     useEffect(() => {
          const fetchCountries = async () => {
               try {
                    const response = await fetch(('https://shreddersbay.com/API/country_api.php?action=select'))
                    const data = await response.json();
                    console.log("countrydata", data);
                    setCountries(data);
               }
               catch (error) {
                    console.log('error fetching countries:', error);
               }
          };
          fetchCountries();
     }, []);

     useEffect(() => {
          if (country) {
               const fetchStates = async () => {
                    try {

                         const response = await fetch((`https://shreddersbay.com/API/state_api.php?action=select&country_id=${country}`))
                         const data = await response.json();
                         console.log("states data", data);
                         setStates(data);
                    }
                    catch (error) {
                         console.log('error fetching states', error);
                    }
               };
               fetchStates();
          }

          else {
               setStates([]);
               setFilterState([]);
               // setAddresses([]);
               // setFilterAddress([]);

          }
     }, [country]);

     useEffect(() => {

          if (state) {
               const fetchCity = async () => {
                    try {
                         const response = await fetch((`https://shreddersbay.com/API/city_api.php?action=select&state_id=${state}`))
                         const data = await response.json();
                         console.log("cities data", data);
                         setCities(data);
                    }
                    catch (error) {
                         console.log("fetching error", error);
                    }
               };
               fetchCity();
          }

          else {
               setCities([]);
               setFilterCity([]);

          }
     }, [state]);

     useEffect(() => {
          if (country) {
               const fetchAddresses = async () => {
                    try {
                         const response = await fetch(`https://shreddersbay.com/API/area_api.php?action=select&city_id=2`);
                         const data = await response.json();
                         console.log("address data", data);
                         setAddresses(data);
                    } catch (error) {
                         console.log('error fetching addresses:', error);
                    }
               };
               fetchAddresses();
          } else {
               setAddresses([]);
          }
     }, [country]);

     const fetchAddress = async () => {
          // const url = "https://shreddersbay.com/API/address_api.php?action=AddrByUserId&user_id=2474"
          const url2 = "https://shreddersbay.com/API/address_api.php?action=AddrByUserId&user_id"

          try {
               const response = await fetch(`${url2}=${user_id}`);
               const data = await response.json();
               console.log("alladdress", data);
               setsavedAddress(data);
          } catch (error) {
               console.log("fetching error", error);
          }
     };
     useEffect(() => {

          if (user_id !== "") {

               fetchAddress();
          }
     }, [user_id]);


     const handleChangeCountry = (event) => {
          const selectedCountryId = event.target.value;
          setCountry(selectedCountryId);
          setState(''); // Reset state when country changes
          setCity(''); // Reset city when country changes
          setAddress('');
          setFilterState([]); // Reset filtered states
          setFilterCity([]); // Reset filtered cities
          setFilterAddress([]);

     };

     const handleChangeState = (event) => {
          const selectedStateId = event.target.value;
          setState(selectedStateId);

          // const filteredCities = cities.filter(city => city.state_id === selectedStateId);
          setFilterCity([]);
          setCity(''); // Reset city when state changes
     };

     const handleChangeCity = (event) => {
          setCity(event.target.value);
     };


     const handleChangeAddress = (event) => {
          setAddress(event.target.value);
     };


     const handleSaveAddress = async () => {
          const formData = new FormData();
          formData.append("user_id", user_id);
          formData.append('country_id', country);
          formData.append('state_id', state);
          formData.append('city_id', city);
          formData.append('houseno', houseNo);
          formData.append('road', road);
          formData.append('pincode', pincode);
          formData.append('landmark', landmark);
          formData.append('area_id', address);
          formData.append('address', `${houseNo},${road}`);

          // 
          // formData.append('country_id', selectedCountryId);
          // formData.append('state_id', selectedStateId);
          // formData.append('city_id', selectedCityId);
          // formData.append('area_id', selectedAreaId);
          // formData.append('address', `${houseno},${address}`);
          // formData.append('landmark', landmark);
          // formData.append('pincode', pincode);
          // formData.append('user_id', userIdApp);
          // 

          console.log(`FormDAta ::>> userid : ${user_id}, country :${country}, state:${state},city :${city}, houseno:${houseNo}, road :${road}, pincode :${pincode}, landmar:${landmark}, address:${address}`);
          try {

               const response = await fetch('https://shreddersbay.com/API/address_api.php?action=insert', {

                    method: 'POST',
                    body: formData,
               })

               if (response.ok) {
                    const result = await response.json();
                    console.log("address saved successfully", result);
                    // close add address
                    handleToggle();


               }
               else {
                    console.log("failed to save address", response.statusText);
               }
          }
          catch (error) {
               console.log("error saving address", error);
          }
     };

     const handleResetData = () => {
          setCountry("");
          setState("");
          setCity("");
          setHouseNo("");
          setRoad("");
          setPincode("");
          setLandmark("");
          setAddress("");
     }

     const handleChangeSelectedAddress = (event) => {
          const selectedId = event.target.value;
          console.log("Selected value:", selectedId); // Debugging: Log selected value
          setSelectedAddress(selectedId);

          const selectedAddressObject = savedAddress.find(address => address.id === selectedId);
          console.log("Selected address object:", selectedAddressObject); // Debugging: Log selected address object
     };



     const [issubmit, setIssubmit] = useState(false);
     const [lastcartId, setLastcartId] = useState('');


     const cartAdd = async () => {
          const formData = new FormData();
          let isPhotoSet = true; // Renamed for clarity
      
          // Process each image in the images array
          images.forEach((base64Image, index) => {
              const [metadata, base64Data] = base64Image.split(',');
              const contentType = metadata.split(':')[1].split(';')[0];
              const blob = base64ToBlob(base64Data, contentType);
              const filename = `image_${index}.jpeg`;
      
              formData.append(`file[]`, blob, filename);
              console.log("filename is", filename);
      
              // Set the first image filename to the context API photo
              if (isPhotoSet) {
                  setgProductForm(prevState => ({
                      ...prevState,
                      photo: filename, // Set only the filename of the first image
                  }));
                  isPhotoSet = false; // Prevent further updates to photo
              }
          });
      
          // Append other product data to formData
          formData.append("user_id", user_id);
          formData.append("addr_id", selectedAddress);
          formData.append("weight", gProductForm.weight);
          formData.append("price", gProductForm.price1);
          formData.append("prod_id", gProductForm.p_id);
          formData.append("title", gProductForm.title);
          formData.append("description", gProductForm.description);
      
          console.log("formData=>", formData);
      
          const urlpost = "https://shreddersbay.com/API/cart_api.php?action=insert";
      
          try {
              const response = await fetch(urlpost, {
                  method: 'POST',
                  body: formData,
              });
      
              // Log the response status and body
              console.log('Response Status:', response.status);
      
              // Check if the response is OK
              if (response.ok) {
               console.log("response cart data =>",response);
                  const data = await response.json(); // Attempt to parse JSON
                  console.log('Response:', data);
              } else {
                  // Log the entire response for debugging
                  const text = await response.text(); // Get response text
                  console.error('Error:', response.statusText, 'Response body:', text);
              }
          } catch (error) {
              console.error('Error uploading images:', error);
          }
      };
      
     const handlePublishOrder = async () => {
          console.log("New publish order is called");

          try {
               const data = await get_user_data(gState.firebase_uid);
               //     alert("hello this is again");
               console.log("Data then is =>", data, gState, gProductForm);

               if (data.verify === "false") {
                    setfirebaseuserData(data);
                    console.log("Publish order is not called;");
                    setIsverifymodalOpen(!isverifymodalOpen);
               } else {
                    console.log("Publish order is called;");
                    console.log("gProductForm:-", gProductForm);
                    console.log("gState=>", gState);

                    const id = generateUniqueId();

                    const productData = await create_product_in_firebase(
                         id,
                         gState.firebase_uid,
                         gProductForm.p_id,
                         gProductForm.title,
                         gProductForm.description,
                         gProductForm.price,
                         gProductForm.weight,
                         gProductForm.photo
                    );
                    const firebase_pid = Object.keys(productData.product)[0];

                    console.log("Product object from Firebase=>", productData);
                    console.log("First=>", firebase_pid);
                    publishorder(firebase_pid);


               }
          } catch (error) {
               console.log("Error fetching user data =>", error);
          }
     };

     const publishorder = async (firebase_pid) => {
          console.log("hello world..");
          const get_url = `https://shreddersbay.com/API/user_api.php?action=select_id&user_id=${user_id}`;

          try {
               const response = await fetch(get_url);
               const rgetdata = await response.json();
               console.log("Cart data in multiple photo=>", rgetdata);

               if (rgetdata.length > 0) {
                    const lastCartId = rgetdata[rgetdata.length - 1].cart_id;
                    console.log("Last cart_id:", lastCartId);
                    setLastcartId(lastCartId);

                    const formData = new FormData();
                    images.forEach((base64Image, index) => {
                         const [metadata, base64Data] = base64Image.split(',');
                         const contentType = metadata.split(':')[1].split(';')[0];
                         const blob = base64ToBlob(base64Data, contentType);
                         formData.append(`file[]`, blob, `image_${ctime(index)}.jpeg`);
                         console.log("filename2=> " + `image_${ctime(index)}.jpeg`);
                         if (index === 0) {
                              setgProductForm({
                                   ...gProductForm,
                                   photo: `image_${ctime(index)}.jpeg`,
                              });
                         }
                         let issetphoto = true;
                         if (issetphoto) {

                              setgProductForm({
                                   ...gProductForm,
                                   photo: `image_${ctime(index)}.jpeg`,

                              })
                              issetphoto = false;
                         }

                    });

                    // Append data to formData
                    formData.append("user_id", user_id);
                    formData.append("addr_id", selectedAddress);
                    formData.append("weight", gProductForm.weight);
                    formData.append("price", gProductForm.price1);
                    formData.append("prod_id", gProductForm.p_id);
                    formData.append("title", gProductForm.title);
                    formData.append("description", gProductForm.description);
                    formData.append("last_cart_id", lastCartId);
                    formData.append("start_date", startDate.toISOString());
                    formData.append("firebase_prodID", firebase_pid);

                    console.log("This is formData=>", formData);
                    console.log("Form data of gProductForm=>", gProductForm);

                    const urlpost = "https://shreddersbay.com/API/orders_api.php?action=insert";
                    // const urlpost = "";
                    const postResponse = await fetch(urlpost, {
                         method: 'POST',
                         body: formData,
                    });

                    if (postResponse.ok) {
                         const data1 = await postResponse.json();
                         console.log('Response:', data1);

                         const firebase_user_data = await get_user_data(gState.firebase_uid);
                         console.log("Firebase user data in multiple photo =>", firebase_user_data);

                         if (firebase_user_data.verify === "true") {
                              console.log(`Firebase formdata testing =::> ${gProductForm.firebase_pid}, ${gState.firebase_uid}, ${gProductForm.p_id}, ${gProductForm.title}, ${gProductForm.description}, ${gProductForm.price}, ${gProductForm.weight}, ${gProductForm.photo}`);

                              await create_product_in_firebase(
                                   gProductForm.firebase_pid,
                                   gState.firebase_uid,
                                   gProductForm.p_id,
                                   gProductForm.title,
                                   gProductForm.description,
                                   gProductForm.price,
                                   gProductForm.weight,
                                   gProductForm.photo
                              );

                              console.log("Product created in Firebase Realtime Database");
                         } else {
                              setIsverifymodalOpen(!isverifymodalOpen);
                         }
                         navigate('/');
                    } else {
                         console.error('Error', postResponse.statusText);
                    }
               } else {
                    console.log("No data found.");
               }
          } catch (error) {
               console.error("Error fetching cart data:", error);
          }
     };




     const [isverifymodalOpen, setIsverifymodalOpen] = useState(false);
     const [firebaseuserData, setfirebaseuserData] = useState({});

     const handlcloseVerifyModal = () => {
          setIsverifymodalOpen((prevstate) => !prevstate);  // Correct toggle logic
     };


     return (

          <Box sx={{
               marginTop: { xs: 8, sm: 0, md: 0 },
               marginBottom: { xs: 4, sm: 0, md: 0 }
          }}>
               <Box
                    sx=
                    {{
                         display: 'flex',
                         alignItems: 'center',
                         padding: '10px',
                         backgroundColor: '#eee',
                         marginTop: 10,
                         boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
               >
                    <ArrowBackIcon
                         sx={{ cursor: 'pointer', marginLeft: '20px' }}
                         onClick={handleBackClick}
                    />

               </Box>
               <h4 className='ads'>POST YOUR ADS</h4>

               <Box
                    sx={{
                         border: '1px solid black',
                         padding: '2%',
                         marginTop: '2%',
                         margin: 'auto',
                         width: { xs: '100%', sm: '70%', md: '50%' }, // Responsive width
                         margin: { xs: '10px', sm: 'auto' }, // Responsive margin
                    }}

               >

                    <h5 className='CatText'>UPLOAD UP TO 10 PHOTOS</h5>

                    <Box style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                         {[...Array(maxBoxes)].map((_, index) => (
                              <div key={index} style={{ position: 'relative', margin: '10px' }}>
                                   {images[index] ? (
                                        <>
                                             <img
                                                  src={images[index]}
                                                  alt={`upload-${index}`}
                                                  sx={{
                                                       width: { xs: '50px', height: '50px', objectFit: 'cover' }
                                                  }}
                                                  style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                             />
                                             <button
                                                  onClick={() => removeImage(index)}
                                                  style={{
                                                       position: 'absolute',
                                                       top: '5px',
                                                       right: '5px',
                                                       background: 'red',
                                                       color: 'white',
                                                       border: 'none',
                                                       borderRadius: '50%',
                                                       width: '20px',
                                                       height: '20px',
                                                       cursor: 'pointer',
                                                  }}
                                             >
                                                  X
                                             </button>
                                        </>
                                   ) : (
                                        <Box
                                             style={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  width: '100px',
                                                  height: '100px',
                                                  border: '2px dashed #ccc',
                                                  cursor: 'pointer',
                                             }}

                                        >
                                             <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                                                  <AddAPhotoOutlinedIcon sx={{ fontSize: 40 }} />
                                             </label>
                                             <input
                                                  type="file"
                                                  id="file-upload"
                                                  multiple
                                                  onChange={(e) => handleImageUpload(e, index)}
                                                  style={{ display: 'none' }}
                                             />


                                        </Box>
                                   )}
                              </div>
                         ))}
                    </Box>

                    <Box sx={{ marginTop: " 6%" }}>
                         <h5 className='CatText'>Manage Address</h5>

                         <Box sx={{ marginTop: "5%" }}>
                              <Button
                                   variant="contained"
                                   startIcon={<AddIcon />}
                                   onClick={() => handleToggle()}
                                   style=
                                   {{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        backgroundColor: '#00457E',
                                        color: '#fff',
                                   }}
                              >
                                   Add Address
                              </Button>

                              {toggle ? (
                                   <Box sx={{ minWidth: 200, marginTop: 5 }}>


                                        <FormControl fullWidth sx={{ marginTop: 3 }}>
                                             <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                                             <Select
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  value={country}
                                                  label="Select Country"
                                                  onChange={handleChangeCountry}
                                             >
                                                  {countries.map((country) => (
                                                       <MenuItem key={country.country_id} value={country.country_id}>
                                                            {country.country_name}
                                                       </MenuItem>
                                                  ))}

                                             </Select>

                                        </FormControl>

                                        <FormControl fullWidth sx={{ marginTop: 3 }}>
                                             <InputLabel id="demo-simple-select-label">Select State</InputLabel>
                                             <Select
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  value={state}
                                                  label="Select State"
                                                  onChange={handleChangeState}
                                             >
                                                  {
                                                       states.map((state) => (
                                                            <MenuItem key={state.state_id} value={state.state_id}>
                                                                 {state.state_name}
                                                            </MenuItem>
                                                       ))
                                                  }


                                             </Select>
                                        </FormControl>

                                        <FormControl fullWidth sx={{ marginTop: 3 }}>
                                             <InputLabel id="demo-simple-select-label">Select City</InputLabel>
                                             <Select
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  value={city}
                                                  label="Select City"
                                                  onChange={handleChangeCity}
                                             >
                                                  {cities.map((city) => (
                                                       <MenuItem key={city.city_id} value={city.city_id}>
                                                            {city.city_name}
                                                       </MenuItem>
                                                  ))
                                                  }
                                             </Select>
                                        </FormControl>

                                        <Box sx={{ marginTop: 2 }}>
                                             <TextField
                                                  id="outlined-basic"
                                                  label="House No, Building Name"
                                                  fullWidth variant="outlined"
                                                  sx={{ marginTop: 2 }}
                                                  value={houseNo}
                                                  onChange={(e) => setHouseNo(e.target.value)}
                                             />


                                             <TextField id="outlined-basic"
                                                  label="Road Name, Area, Colony"
                                                  fullWidth variant="outlined"
                                                  sx={{ marginTop: 2 }}
                                                  value={road}
                                                  onChange={(e) => setRoad(e.target.value)}
                                             />


                                             <Grid container spacing={2}>
                                                  <Grid item xs={6}>
                                                       <TextField
                                                            id="outlined-basic"
                                                            label="Pincode"
                                                            variant="outlined"
                                                            fullWidth
                                                            sx={{ marginTop: 2 }}
                                                            value={pincode}
                                                            onChange={(e) => setPincode(e.target.value)}
                                                       />
                                                  </Grid>
                                                  <Grid item xs={6}>
                                                       <TextField
                                                            id="outlined-basic"
                                                            label="Landmark"
                                                            variant="outlined"
                                                            fullWidth
                                                            sx={{ marginTop: 2 }}
                                                            value={landmark}
                                                            onChange={(e) => setLandmark(e.target.value)}
                                                       />
                                                  </Grid>
                                             </Grid>



                                        </Box>

                                        <FormControl fullWidth sx={{ marginTop: 3 }}>
                                             <InputLabel id="demo-simple-select-label">Type of address</InputLabel>
                                             <Select
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  value={address}
                                                  label="Address"
                                                  onChange={handleChangeAddress}
                                             >
                                                  {
                                                       addresses.map((address) => (
                                                            <MenuItem key={address.area_id} value={address.area_id}>
                                                                 {address.area_name}
                                                            </MenuItem>
                                                       ))
                                                  }
                                             </Select>
                                        </FormControl>

                                        <AwesomeButton

                                             className="animatedButtonSave"
                                             type='teal'

                                             style={{ color: Color.light.text }}
                                             onPress={handleSaveAddress}
                                        >
                                             Save Address
                                        </AwesomeButton>

                                        <AwesomeButton

                                             className="animatedButton1"
                                             type='teal'
                                             style={{ color: '#fff' }}
                                             onPress={handleResetData}
                                        >
                                             Reset
                                        </AwesomeButton>


                                   </Box>



                              )

                                   :
                                   (
                                        <>
                                             <Box sx={{ marginTop: 5 }}>
                                                  <FormControl component="fieldset">
                                                       <FormLabel component="legend">Saved Addresses</FormLabel>
                                                       <RadioGroup value={selectedAddress} onClick={handleChangeSelectedAddress}>
                                                            {savedAddress.map((address, index) => (
                                                                 <FormControlLabel
                                                                      key={index}
                                                                      value={address.addr_id} // This should match the `selectedAddress` value
                                                                      control={<Radio />}
                                                                      label={
                                                                           <Card sx={{
                                                                                minWidth: { xs: 300, sm: 300, md: 500 },
                                                                           }}>
                                                                                <CardContent>
                                                                                     <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
                                                                                          {address.address},{address.landmark}
                                                                                     </Typography>
                                                                                     <Typography variant="body2">
                                                                                          {address.city_name}, {address.state_name}, {address.country_name}
                                                                                     </Typography>
                                                                                     <Typography>
                                                                                          {address.pin_code}
                                                                                     </Typography>
                                                                                </CardContent>
                                                                           </Card>
                                                                      }
                                                                 />
                                                            ))}
                                                       </RadioGroup>
                                                  </FormControl>
                                             </Box>

                                             <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                                                  <Box>
                                                       <CalendarMonthOutlinedIcon />
                                                  </Box>
                                                  <Box>
                                                       <DatePicker selected={startDate} onChange={(date) => setStartDate1(date)} />
                                                  </Box>
                                             </Box>
                                             <VerifyModal handleClose={handlcloseVerifyModal} isOpen={isverifymodalOpen} data={firebaseuserData} />
                                             <AwesomeButton

                                                  className="animatedButton1"
                                                  type='teal'
                                                  style={{ color: '#fff' }}
                                                  onPress={handlePublishOrder}
                                             >
                                                  Publish Your Ad
                                             </AwesomeButton>
                                        </>
                                   )
                              }
                         </Box>
                    </Box>
               </Box>



          </Box>

     );
};

export default MultiplePhoto