import Shreddersbay1 from "../../src/Images/Shreddersbay (1).png"
import Shreddersbay2 from "../../src/Images/Shreddersbay (2).png"
import Shreddersbay4 from "../../src/Images/Shreddersbay (4).png"
import Steel from "../../src/Images/steel.jpeg"
import Aluminium from "../../src/Images/aluminum.jpeg"
import Bike from "../../src/Images/bike.jpg"
import Brass2 from "../../src/Images/brass2.jpg"
import cable from "../../src/Images/cable.jpeg"
import car from "../../src/Images/car.jpg"
import computer from "../../src/Images/computer.jpg"
import cycle from "../../src/Images/cycle.jpg"
import iron from "../../src/Images/iron.jpeg"
import lead1 from "../../src/Images/lead1.jpg"
import newpaper from "../../src/Images/newspaper.jpeg"
import plastic from "../../src/Images/plastic.jpeg"
import pump from "../../src/Images/pump.jpg"
import railway from "../../src/Images/railway.jpeg"
import ship from "../../src/Images/ship.jpg"
import username from "../Images/username.png"
import signupshreds from "../Images/signupshreds.png"
import banner from "../Images/ShreddersBay.Com.png"
import mobileShreddersbay1 from "../Images/mobileview1.png"
import vectorBuy from "../Images/vectorBuy.jpg"
import vectorSell from "../Images/vectorSell.png"
import updateProfile from "../Images/emptyupdate.jpg"
import auction from "../Images/auction2.jpg"
import downloadApp from "../Images/mobileappdownload.png"





export const ImagesApp = {
    Shreddersbay1,
    Shreddersbay2,
    Shreddersbay4,
    Steel,
    Aluminium,
    Bike,
    Brass2,
    cable,
    car,
    computer,
    cycle,
    iron,
    lead1,
    newpaper,
    plastic,
    pump,
    railway,
    ship,
    username,
    signupshreds,
    banner, 
    mobileShreddersbay1,
    vectorBuy,
    vectorSell,
    updateProfile,
    auction,
    downloadApp,

}