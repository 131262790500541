import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faEllipsisVertical, faUser } from '@fortawesome/free-solid-svg-icons';
import { getDatabase, ref, onValue } from 'firebase/database';
import { Context } from '../../Utils/ContextAPI/ContextProvider';
import { fetchProductDetails, fetchUserDetails } from '../../Constants/Firebase_functions';

function ChatLeft() {
    const [gState, , , , , , , chatGState, setChatGState] = useContext(Context);
    const { firebase_uid } = gState;

    const [conversations, setConversations] = useState([]);
    const [filteredConversations, setFilteredConversations] = useState([]);
    const [activeTab, setActiveTab] = useState('all'); // 'all', 'sender', or 'receiver'
    const [userNames, setUserNames] = useState({});
    const [productIDs, setProductIDs] = useState({});

    useEffect(() => {
        const db = getDatabase();
        const conversationsRef = ref(db, 'messages/');

        const unsubscribe = onValue(conversationsRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const loadedConversations = [];
                const tempProductIDs = {};

                // Check if firebase_uid is valid
                if (!firebase_uid) {
                    console.log("Invalid firebase_uid:", firebase_uid);
                    return; // Exit if uid is invalid
                }

                Object.keys(data).forEach((conversationId) => {
                    const messages = data[conversationId];
                    const messagesArray = Object.values(messages);

                    if (messagesArray.length > 0) {
                        messagesArray.sort((a, b) => a.timestamp - b.timestamp);

                        const recentMessage = messagesArray[messagesArray.length - 1];
                        const [senderId, receiverId] = conversationId.split('_');

                        // Only keep conversations where firebase_uid is either sender or receiver
                        if (senderId === firebase_uid || receiverId === firebase_uid) {
                            loadedConversations.push({ conversationId, messages: messagesArray });
                            
                            // Extract product ID
                            if (recentMessage.productId) {
                                tempProductIDs[conversationId] = recentMessage.productId;
                            }
                        }
                    }
                });

                setConversations(loadedConversations);
                setProductIDs(tempProductIDs);
                filterConversations(loadedConversations);
            }
        });

        return () => unsubscribe(); // Cleanup
    }, [firebase_uid]);

    const filterConversations = (conversations) => {
        const tempSenderConversations = [];
        const tempReceiverConversations = [];

        conversations.forEach(conv => {
            const [senderId, receiverId] = conv.conversationId.split('_');
            if (senderId === firebase_uid) {
                tempSenderConversations.push(conv);
            } else if (receiverId === firebase_uid) {
                tempReceiverConversations.push(conv);
            }
        });

        // Sort by timestamp
        tempSenderConversations.sort((a, b) => b.messages[b.messages.length - 1].timestamp - a.messages[a.messages.length - 1].timestamp);
        tempReceiverConversations.sort((a, b) => b.messages[b.messages.length - 1].timestamp - a.messages[a.messages.length - 1].timestamp);

        if (activeTab === 'all') {
            setFilteredConversations([...tempSenderConversations, ...tempReceiverConversations]);
        } else if (activeTab === 'sender') {
            setFilteredConversations(tempSenderConversations);
        } else if (activeTab === 'receiver') {
            setFilteredConversations(tempReceiverConversations);
        }
    };

    useEffect(() => {
        const fetchUserNames = async () => {
            const db = getDatabase();
            const usersRef = ref(db, 'users/');

            onValue(usersRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const names = {};
                    Object.keys(data).forEach((userId) => {
                        names[userId] = data[userId].name; // Adjust according to your data structure
                    });
                    setUserNames(names);
                }
            });
        };

        fetchUserNames();
    }, []);

    useEffect(() => {
        // Filter conversations based on active tab
        filterConversations(conversations);
    }, [activeTab, conversations]);

    const handleRightChat =async (conversationId, senderId, receiverId, productId) => {
     console.log("handle right chat=>1",conversationId,"2", senderId,"3", receiverId,"4",productId)
     //    setChatGState({
     //        bfirebaseUID: senderId,
     //        bfirebaseProdID: productId,
     //        afirebaseUID: receiverId,
     //    });
    await setChatGState({

          bfirebaseUID: receiverId,
          bfirebaseProdID: productId,
          conversationID:conversationId,
      });
      console.log("chatgstate3=>",chatGState);
    };

    const [userDetails, setUserDetails] = useState({});
    const [productDetails, setProductDetails] = useState({});

    // Function to fetch and set user details
    const fetchUserAndProductDetails = async (userName, productID) => {
        try {
            const userdata = await fetchUserDetails(userName);
            const productdata = await fetchProductDetails(productID);

            // Update the state with the fetched data
            setUserDetails(prev => ({ ...prev, [userName]: userdata }));
            setProductDetails(prev => ({ ...prev, [productID]: productdata }));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    return (
        <div style={{ height: "80vh", overflowY: 'scroll' }}>
            <div className='chat-header' style={{ padding: 20, display: 'flex', borderBottom: '2px solid gray' }}>
                <div className="inbox" style={{ flex: 1, textAlign: 'left' }}>INBOX</div>
                <div className="search" style={{ flex: 0.1, alignItems: 'flex-start' }}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
                <div className="edit" style={{ flex: 0.1, alignItems: 'flex-start' }}>
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                </div>
            </div>

            <div className="tabs" style={{ display: 'flex', padding: 10, borderBottom: '1px solid gray' }}>
                <div className={`tab ${activeTab === 'all' ? 'active' : ''}`} style={{ flex: 1, textAlign: 'center', cursor: 'pointer' }} onClick={() => setActiveTab('all')}>All</div>
                <div className={`tab ${activeTab === 'sender' ? 'active' : ''}`} style={{ flex: 1, textAlign: 'center', cursor: 'pointer' }} onClick={() => setActiveTab('sender')}>Buying</div>
                <div className={`tab ${activeTab === 'receiver' ? 'active' : ''}`} style={{ flex: 1, textAlign: 'center', cursor: 'pointer' }} onClick={() => setActiveTab('receiver')}>Selling</div>
            </div>

            {/* <div className="chat-list">
                {filteredConversations.reverse().map((conv, index) => {
                    const messages = conv.messages;
                    console.log("recent message1s=>",messages);
                    const recentMessage = messages[messages.length - 1];
                    console.log("recent message=>",recentMessage);
                    const [userId1, userId2] = conv.conversationId.split('_').filter(id => id !== firebase_uid);
                    const userName = userNames[recentMessage.senderId === firebase_uid ? userId2 : userId1] || `User ${userId1}`;
                    const productID = productIDs[conv.conversationId];

                    return (
                        <div key={index} className="chat-item" style={{ padding: 10, borderBottom: '1px solid gray' }} onClick={() => handleRightChat(conv.conversationId, recentMessage.senderId, recentMessage.receiverId, productID)}>
                            <div className="left-image" style={{ display: 'inline-block' }}>
                                <FontAwesomeIcon icon={faUser}
                                    style={{
                                        background: 'lightgray',
                                        border: '1px solid gray',
                                        padding: 10,
                                        borderRadius: 50
                                    }} />
                            </div>

                            <div className="chat-user" style={{ display: 'inline-block', paddingLeft: '10px' }}>
                                <h5>{recentMessage.senderId === firebase_uid ? `Buying from: ${userName}` : `Selling to: ${userName}`}</h5>
                                <p>{recentMessage.content}</p>
                                <small>{new Date(recentMessage.timestamp).toLocaleString()}</small>
                                {productID && <p>Product ID: {productID}</p>}
                            </div>
                        </div>
                    );
                })}
            </div> */}
            <div className="chat-list">
    {filteredConversations.reverse().map((conv, index) => {
        const messages = conv.messages;
        const recentMessage = messages[messages.length - 1]; // Getting the last message in the conversation
        const [userId1, userId2] = conv.conversationId.split('_').filter(id => id !== firebase_uid);
        const userName = userNames[recentMessage.senderId === firebase_uid ? userId2 : userId1] || `User ${userId1}`;
        const productID = productIDs[conv.conversationId];
        
        // fetchUserAndProductDetails(userName, productID);
      
        return (
            <div key={index} className="chat-item" style={{ padding: 10, borderBottom: '1px solid gray' }} onClick={() => handleRightChat(conv.conversationId, recentMessage.senderId, recentMessage.receiverId, productID)}>
                <div className="left-image" style={{ display: 'inline-block', cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faUser}
                        style={{
                            background: 'lightgray',
                            border: '1px solid gray',
                            padding: 10,
                            borderRadius: 50
                        }} />
                </div>

                <div className="chat-user" style={{ display: 'inline-block', paddingLeft: '10px', width: '80%', cursor:'pointer' }}>
                    {/* Display the user's name */}
                    <h5>
                        {recentMessage.senderId === firebase_uid
                            ? `Buying from: ${userName}`
                            : `Selling to: ${userName}`}
                    </h5>
                    
                    {/* Display the most recent message content */}
                    <p>{recentMessage.content}</p>
                    
                    {/* Display the timestamp of the most recent message */}
                    <small>{new Date(recentMessage.createdAt).toLocaleString()}</small>
                    
                    {/* Show the associated product ID if available */}
                    {productID && <p>Product ID: {productID}</p>}
                </div>
            </div>
        );
    })}
</div>

        </div>
    );
}

export default ChatLeft;
