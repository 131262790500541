import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "../Cred/Slider.css";
import { ImagesApp } from '../../Images/ImagesApp';

function Slider() {
  const [ismobile, setIsMobile] = useState(false);


  


  return (
    <Carousel
      className='main-slide'
      autoPlay={true}
      interval={3000} // 3 seconds interval
      infiniteLoop={true} // Loop the slides infinitely
      showThumbs={false} // Hide the thumbnails
      showStatus={false} >



      <div>    
          <img src={ImagesApp.Shreddersbay1} alt="Desktop Shreddersbay" />    
      </div>

      <div>
        <img src={ImagesApp.Shreddersbay2} />
      </div>


      <div>
        <img src={ImagesApp.Shreddersbay4} />
      </div>

      
    </Carousel>
  )
}

export default Slider