import React, { useContext, useState, useEffect } from 'react';
import ChatLeft from './ChatLeft';
import ChatRight from './ChatRight';
import { generateUniqueMessageId, listenForMessageStatusChanges, markMessageAsDelivered, markMessageAsSeen, sendMessage } from '../../Constants/Firebase_functions';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Utils/ContextAPI/ContextProvider';
import EmptyChatModal from './EmptyChatModal';


function ChatInbox() {
  const location = useLocation();
  const [gState, setGState, LogOut, gProductForm, setgProductForm, catagorySelectedData, setcatagorySelectedData, chatGState, setChatGState] = useContext(Context);
  // conversationID

  return (

    <div style={{ height: '80vh' , marginInline:'20px'}}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '80px',
        marginInline: { sx: '0', md: '20px' }
      }}>
        <div
          style={{
            flex: 1.5,
            backgroundColor: 'lightgray',
          }}>
          <ChatLeft />
        </div>
        {
          chatGState.conversationID ?
            <div
              style={{
                flex: 3,
                backgroundColor: 'lightpink',
                border: '0.5px solid lightpink',
              }}>

              <ChatRight />
            </div>
            :
            <div
              style={{
                flex: 3,
                backgroundColor: 'lightgreen',
                border: '0.5px solid lightpink',
                alignItems: 'center',
                justifyContent: 'center'
              }}>

              <h1>please choose one text</h1>

              <EmptyChatModal />
            </div>
        }

      </div>
    </div>

  );
}

export default ChatInbox;
