import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import PlayStoreIcon from '@mui/icons-material/Android'; // Substitute for Play Store
import YouTubeIcon from '@mui/icons-material/YouTube';

function footer() {
  return (
    <Box 
      sx={{ 
        backgroundColor: '#333', 
        color: 'white', 
        p: 2, 
        left: 0, 
        bottom: 0, 
        width: '100%', 
        textAlign: 'center' 
      }}
    >
      <Typography variant="body2" component="p">
        &copy; 2024 Your Company. All rights reserved.
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Link href="/privacy-policy" color="inherit" underline="hover" sx={{ mx: 2 }}>
          Privacy Policy
        </Link>
        <Link href="/terms-of-service" color="inherit" underline="hover" sx={{ mx: 2 }}>
          Terms of Service
        </Link>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Link href="https://play.google.com/store/apps/details?id=com.shreddersbay" target="_blank" color="inherit" sx={{ mx: 2 }}>
          <PlayStoreIcon sx={{ fontSize: 30 }} />
        </Link>
        <Link href="https://youtu.be/hJCece4J1zI?si=R86roYEGClACNTGo" target="_blank" color="inherit" sx={{ mx: 2 }}>
          <YouTubeIcon sx={{ fontSize: 30, color: 'red' }} />
        </Link>
      </Box>
    </Box>
  );
}

export default footer;
