import React, { useState, useEffect ,useContext} from 'react';
import './VerifyModal.css'; // Add CSS for modal styling
import { update_user_in_firebase } from '../../Constants/Firebase_functions';
import { Context } from '../../Utils/ContextAPI/ContextProvider';

function VerifyModal({ handleClose, isOpen, data = {} }) {
     const [gState, setGState, LogOut, gProductForm, setgProductForm] = useContext(Context);
     const { user_id, user_name, user_email } = gState;
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    mobile: ''
  });

  useEffect(() => {
    // Update formData when `data` prop changes
    if (data) {
     console.log("data form firebase=:>",data);
      setFormData({
        email: data.email || '',
        name: data.username || '',
        mobile: data.phone_number || ''
      });


    }
  }, [data]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = () => {
    console.log('Form Data:', formData);
    if(formData.mobile.length === 10 ){

         update_user_in_firebase(gState.firebase_uid,formData.email,formData.name,formData.mobile);
         handleClose();
    }else{
     alert("please enter correct phone number");
    }
    
  };

  // If `isOpen` is false, don't render the modal
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {/* Close Button (Cross) in the Top-Right Corner */}
        <button className="close-button" onClick={handleClose}>×</button>
        <h2>Verify Information</h2>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="name"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="mobile">Mobile:</label>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
          />
        </div>
        <div className="button-group">
          <button onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );
}

export default VerifyModal;
