import React, { useContext, useState } from 'react';
import { Box, IconButton, Button, Typography, TextField, Link,InputAdornment,Snackbar, Alert ,  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GWebbtn from '../../Components/Cred/CustomComp/GWebbtn';
import { useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import {faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';

const CustomModalSignin = ({ open, handleClose, handleLogin, handleOpenSignup, email, setEmail, password, handleonchangepassword, ImagesApp }) => {
  const navigation = useNavigate();
  const [openLogin, setOpenLogin] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email1, setEmail1] = useState(email || "");
  const [passwordError, setPasswordError] = useState(false);  // For password error state
  const [errorMessage, setErrorMessage] = useState(''); 




  const isMobile = useMediaQuery('(max-width:600px)');


  const togglePasswordVisibility=()=>{
    setShowPassword(!showPassword);
  }

  const handleCloseError = () => {
    setPasswordError(false);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  }
  if (!open) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1300, // Ensure the modal is on top of other elements
      }}
    >
      <Box
        sx={{
          width: '80%',
          maxWidth: '800px',
          backgroundColor: 'white',
          borderRadius: '8px',
          overflow: 'hidden',
          position: 'relative',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'row',
          border: '1px solid gray',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>


        <Box sx=
          {{
            width: '50%',
            backgroundColor: 'darkgray',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            display: isMobile ? 'none' : '',
          }}>
          <img src={ImagesApp.username} width={300} height={400} alt="Username" />
        </Box>

        <Box sx=
          {{
            width: isMobile ? '100%' : '50%',
            padding: '20px'
          }}>
          <Box component="form" sx={{ mt: 2 }}>
            <Typography sx={{ alignContent: 'center', fontWeight: 'bold' }}>Welcome to Shreddersbay</Typography>

            <Box sx={{ marginTop: 3 }}>
              <TextField
                label="Username"
                fullWidth
                value={email1 || email}
                onChange={(e) => {setEmail1(e.target.value);
                  setEmail(e.target.value);
                }}
                sx={{ mb: 3 }}
                InputProps={{
                  sx: {
                    height: '40px',
                    padding: '0px',
                  },
                }}
                InputLabelProps={{
                  sx: {
                    bottom: '10%',
                  },
                }}
              />
              <TextField
                label="Password"
                fullWidth
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handleonchangepassword}
                InputProps={{
                  sx: {
                    height: '40px',
                    padding: '0px',
                  },

                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end" sx={{marginRight: 2, fontSize: '1.2rem'}}>
                        <FontAwesomeIcon icon={showPassword ? faEye: faEyeSlash } />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

             

              <Typography sx={{ textAlign: 'right',cursor:'pointer' }} onClick={()=>{
                handleClose()
                setEmail("")
                setEmail1("")
                navigation("/forgotpassword",{state:{email1}})}}>Forget Password</Typography>
            </Box>

            <Box sx={{ marginTop: 5 }}>
              <Button variant="contained" sx={{ width: '100%', backgroundColor: '#00457E' }} onClick={handleLogin}>
                Login
              </Button>
            </Box>

            <Snackbar open={passwordError} autoHideDuration={3000} onClose={handleCloseError}>
              <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                {errorMessage}
              </Alert>
            </Snackbar>

            <Box sx={{ textAlign: 'center', marginTop: 2, marginBottom: 2, justifyContent: 'center', display: 'flex' }}>

              <GWebbtn handleClose={handleClose} />
            </Box>

            <Box
              sx={{
                textAlign: 'center',
                marginTop: 3,
                marginBottom: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >


              <Typography variant="body1">
                New Shreddersbay?{' '}
                <Link onClick={handleOpenSignup} underline="hover" sx={{ fontWeight: 'bold', cursor: 'pointer' }}>
                  Create Account
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomModalSignin;





