const tintColorLight = '#0a7ea4';
const tintColorDark = '#fff';


export const Color = {
     light: {
          text: '#fff',
          background: '#00457E',
          tint: tintColorLight,
          icon: '#808080',
          tabIconDefault: '#673AB7',
          tabIconSelected: tintColorLight,
     },
     dark: {
          text: '#ECEDEE',
          background: 'red',
          tint: tintColorLight,
          icon: '#808080',
          tabIconDefault: '#673AB7',
          tabIconSelected: tintColorLight,
     },
     cool: {
          text: '#673AB7',
          background: 'black',
          tint: tintColorLight,
          icon: '#808080',
          tabIconDefault: '#673AB7',
          tabIconSelected: tintColorLight,
     },
}
