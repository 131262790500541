
import React, { useState } from 'react';
import { Box, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const SearchProduct = () => {
  const [productName, setProductName] = useState('');
  const navigate = useNavigate(); // React Router hook to navigate

  const handleSubmit = (e) => {
    e.preventDefault();

    // Redirect to the results page with the product name as a query parameter
    navigate(`/result?productName=${productName}`);
  };

  return (
    <Box sx={{ padding: '20px' }}>
    {/* Search box */}
    <Box
      component="form"
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
        borderRadius: '4px',
        backgroundColor: '#e4e8ed',
        width: '100%',
        maxWidth: '600px', // Increase the maxWidth to make the input wider
        margin: '0 auto',
      }}
      onSubmit={handleSubmit}
    >
      <InputBase
        placeholder="Find Product"
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
        sx={{
          flex: 1,
          paddingRight: '50px',
          width: '100%', // Keep width at 100% to take full available space
        }}
      />
      <IconButton type="submit" sx={{ padding: '5px' }}>
        <SearchIcon />
      </IconButton>
    </Box>
  </Box>
  



  );
};

export default SearchProduct;