

export const Url={

     get:{
      Producturl:'https://shreddersbay.com/API/product_api.php?action=select',

     },
     post:{

     },
     delete:{

     },
     update:{

     },
     imgUrl:{

     }
}