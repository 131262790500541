import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../Utils/ContextAPI/ContextProvider';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Color } from '../../Constants/Color';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



function AuctionCurrentComDetail() {
  const [orders, setOrders] = useState([]);
  const [completeOrders, setCompleteOrders] = useState([]);
  const [gState] = useContext(Context);
  const { user_id } = gState;
  const navigate = useNavigate();
  const [completedOrders, setCompletedOrders] = useState([]);


  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [selectedOrder, setselectedOrder] = useState(null);
  const [auctionData, setAuctionData] = useState([]);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

//   const handleOpenDialog = (order) => {
//     setselectedOrder(order);
//     setOpen(true);
//   };

//   const handleCloseDialog = (order) => {
//     setOpen(false);
//   }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    // Function to fetch auction orders dynamically
    const fetchAuctionOrders = async () => {
      try {
        // Initialize FormData and append user_id
        const formData = new FormData();
        formData.append('user_id', user_id);

        // Making the fetch request
        const response = await fetch(
          'https://shreddersbay.com/API/auctionOrder_api.php?action=select_current',
          {
            method: 'POST',
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();
          setAuctionData(data); // Storing the auction data in state
        } else {
          console.error('Failed to fetch auction orders.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // Only fetch if user_id is available
    if (user_id) {
      fetchAuctionOrders();
    }
  }, [user_id]);

  const handleBackClick = () => {
    navigate('/orderauctiondetail');
};

//   useEffect(() => {
//     const fetchCompletedOrders = async () => {
//       const formData = new FormData();
//       formData.append('user_id', user_id); // Append the user_id from context

//       try {
//         const response = await fetch('https://shreddersbay.com/API/orders_api.php?action=select_complete', {
//           method: 'POST',
//           body: formData,
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setCompletedOrders(data); // Assuming the API returns an array of completed orders
//           console.log('Fetched Completed Orders:', data);
//         } else {
//           console.error('Failed to fetch completed orders:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Error fetching completed orders:', error);
//       }
//     };

//     if (user_id) {
//       fetchCompletedOrders(); // Fetch completed orders when user_id is available
//     }
//   }, [user_id]);

//   const handleComplete = async () => {
//     const bookingId = selectedOrder.booking_id;
//     const formData = new FormData();
//     formData.append('booking_id', bookingId);

//     try {
//       const response = await fetch('https://shreddersbay.com/API/orders_api.php?action=complete', {
//         method: 'POST',
//         body: formData,
//       });

//       if (response.ok) {
//         console.log(`Order ${bookingId} marked as complete.`);
//         setOrders(orders.filter(order => order.booking_id !== bookingId));
//         setCompleteOrders([...completeOrders, selectedOrder]); // Move to complete orders list
//         setValue(1); // Navigate to "Complete_Order" tab
//       } else {
//         console.error('Failed to complete the order:', response.statusText);
//       }
//     } catch (error) {
//       console.error('Error completing the order:', error);
//     } finally {
//       setOpen(false);
//     }
//   };

  return (
    <div>

<Box
                    sx=
                    {{
                         display: 'flex',
                         alignItems: 'center',
                         padding: '10px',
                         backgroundColor: '#eee',
                         marginTop: {xs:8,md:5},
                         boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
               >
                    <ArrowBackIcon
                         sx={{ cursor: 'pointer', marginLeft: '20px' }}
                         onClick={handleBackClick}
                    />

               </Box>
      <Box sx=
        {{
          bgcolor: 'background.paper',
          width: { xs: '90%', sm: '80%', md: 800 },
          margin: 'auto',
          marginTop: 10,
          border: 2,
          borderColor: "#eee",
          padding: { xs: '0%', sm: '30%', md: 10 }
        }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Current_Auction" {...a11yProps(0)} sx={{ backgroundColor: '#00457E', color: '#fff' }} />
            <Tab label="Complete_Auction" {...a11yProps(1)} sx={{ backgroundColor: '#00457E', color: '#fff' }} />

          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Box sx={{
            width: { xs: '100%', sm: '80%', md: '100%' }
          }}>

            {auctionData && auctionData.length > 0 ? (
              <Card variant="outlined" >
                <React.Fragment>
                  {auctionData.map((order, index) => (
                    <CardContent key={index} sx=
                      {{
                        borderBottom: 1,
                        borderColor: '#eee',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)'
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                            <Box sx=
                              {{
                                textAlign: 'left',
                                fontSize: 20,
                                color: Color.light.background,

                              }}>{order.p_name}</Box>
                            <Box sx={{ textAlign: 'left', fontFamily: 'monospace' }}>Booking Id: {order.booking_id}</Box>
                            <Box sx={{ textAlign: 'left', fontFamily: 'monospace' }}>Weight: {order.total_weight}</Box>
                            <Box sx={{ textAlign: 'left', fontFamily: 'monospace' }}>Approx. Price: {order.approx_price}</Box>
                            <Box sx={{ textAlign: 'left', fontFamily: 'monospace' }}>Booking Date: {order.booking_date}</Box>
                            <Box sx={{ textAlign: 'left', fontFamily: 'monospace' }}>Schedule Date: {order.schedule_date}</Box>
                          </Typography>
                        </Grid>

                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <CardActions>
                            <Button
                              size="small"
                              variant="contained"
                              style={{ backgroundColor: Color.light.background }}
                            //   onClick={() => handleOpenDialog(order)}
                            >
                              Complete
                            </Button>
                          </CardActions>
                        </Grid>
                      </Grid>
                    </CardContent>

                  ))}


                </React.Fragment>

              </Card>
            ) : (
              <Box>
                No current orders found.
              </Box>


            )}


            {/* <Dialog
              open={open}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Complete Order?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to mark this order as complete?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleComplete} color="primary" autoFocus>
                  Complete
                </Button>
              </DialogActions>
            </Dialog> */}
          </Box>

        </TabPanel>


        <TabPanel value={value} index={1} dir={theme.direction}>
          {/* <Box sx={{
            minWidth: 275,
            width: { xs: '100%', sm: '80%', md: '100%' }
          }}>

            {completedOrders && completedOrders.length > 0 ? (
              <Card variant="outlined" >
                <React.Fragment>
                  {completedOrders.map((order, index) => (
                    <CardContent key={index} sx=
                      {{
                        borderBottom: 1,
                        borderColor: '#eee',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)'
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                            <Box sx=
                              {{
                                textAlign: 'left',
                                fontSize: 20,
                                color: Color.light.background,

                              }}>{order.p_name}</Box>
                            <Box sx={{ textAlign: 'left', fontFamily: 'monospace' }}>Booking Id: {order.booking_id}</Box>
                            <Box sx={{ textAlign: 'left', fontFamily: 'monospace' }}>Weight: {order.total_weight}</Box>
                            <Box sx={{ textAlign: 'left', fontFamily: 'monospace' }}>Approx. Price: {order.approx_price}</Box>
                            <Box sx={{ textAlign: 'left', fontFamily: 'monospace' }}>Booking Date: {order.booking_date}</Box>
                            <Box sx={{ textAlign: 'left', fontFamily: 'monospace' }}>Schedule Date: {order.schedule_date}</Box>
                          </Typography>
                        </Grid>

                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {/* <CardActions>
             <Button 
               size="small" 
               variant="contained" 
               style={{backgroundColor: Color.light.background}}
               onClick={() => handleOpenDialog(order)}
             >
               Complete
             </Button>
           </CardActions> }
                        </Grid>
                      </Grid>
                    </CardContent>

                  ))}


                </React.Fragment>

              </Card>
            ) : (
              <Box>
                No current orders found.
              </Box>


            )}



          </Box> */}

        </TabPanel>

      </Box>

    </div>
  );
}

export default AuctionCurrentComDetail;
