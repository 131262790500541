import React, { useState, useContext, useEffect, useRef } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { TextField, Button, Typography, Paper, Container, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import Footer from "../Home/Footer";

import { Context } from '../../Utils/ContextAPI/ContextProvider';
import { useLocation, useNavigate } from 'react-router-dom';

const CustomModalForgotPassword = () => {
  const [gState, setGState, LogOut, gProductForm, setgProductForm, catagorySelectedData, setcatagorySelectedData, chatGState, setChatGState] = useContext(Context);
  const location = useLocation();
  const emailFromLocation = location.state?.email1 || ''; // Get the email from useLocation (if available)
  const navigation = useNavigate();

  const [email, setEmail] = useState(emailFromLocation);
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const auth = getAuth();
  const emailInputRef = useRef(null); // Ref for email input
  const passwordInputRef = useRef(null); // Ref for password input

  // Automatically focus on appropriate input field after mounting
  useEffect(() => {
    if (emailFromLocation) {
      // If email comes from location, focus on password input
      passwordInputRef.current.focus();
    } else {
      // If no email from location, focus on email input
      emailInputRef.current.focus();
    }
  }, [emailFromLocation]);

  const handlePasswordReset = (e) => {
    e.preventDefault();

    // Reset password in Firebase
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccess('Firebase password reset email sent. Now updating MySQL...');
        setSnackbarMessage('Password reset email sent.');
        setSnackbarOpen(true);
        updatePasswordInMySQL(); // Call the function to update in MySQL
        alert("please confirm your password on your email via click on a click.");
        navigation("/");
      })
      .catch((error) => {
        setError(error.message);
        setSnackbarMessage('Error sending password reset email.');
        setSnackbarOpen(true);
      });
  };

  // Function to update password in MySQL via API
  const updatePasswordInMySQL = () => {
    const formData = new FormData();
    formData.append('email', email);  // Email of the user
    formData.append('password', password);  // New password

    fetch('https://shreddersbay.com/API/user_api.php?action=reset_pass', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          setSuccess('Password updated in MySQL as well.');
          setSnackbarMessage('Password updated in MySQL.');
          setSnackbarOpen(true);
        } else {
          setError('Error updating password in MySQL: ' + data.message);
          setSnackbarMessage('Error updating password in MySQL.');
          setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        setError('Error: ' + error.message);
        setSnackbarMessage('Error updating password in MySQL.');
        setSnackbarOpen(true);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
  <div style={{marginBottom:"1%"}}>
    
    <Container component="main" maxWidth="xs" style={{marginTop:"10%"}}>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20%' }}>
        <Typography variant="h5" align="center" gutterBottom>
          Reset Password
        </Typography>
        <form onSubmit={handlePasswordReset} noValidate>
          <TextField
            inputRef={emailInputRef} // Ref for email input
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Your Email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!!emailFromLocation} // Disable if email is provided from location
          />
          <TextField
            inputRef={passwordInputRef} // Ref for password input
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="New Password"
            type="password"
            autoComplete="new-password"
            value={password}
            autoFocus={!emailFromLocation} // AutoFocus only if email is not from location
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: '20px' }}
          >
            Reset Password
          </Button>
        </form>
        {success && <Typography color="success.main" align="center" style={{ marginTop: '10px' }}>{success}</Typography>}
        {error && <Typography color="error.main" align="center" style={{ marginTop: '10px' }}>{error}</Typography>}
      </Paper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={error ? "error" : "success"}>
          {snackbarMessage}
        </Alert>
      </Snackbar>


    </Container>


    </div>
    <div style={{position:'absolute', bottom:0,width:'100%'}}>
 <Footer/>
    </div>
    </div>
  
         
  );
};

export default CustomModalForgotPassword;
