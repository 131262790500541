import React, { useEffect, useState,useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
// import { ImagesApp } from '../../Images/ImagesApp';
import "../Home/Orderdetails.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Paper from '@mui/material/Paper';
import { getDatabase, ref, update, onValue, set,get,child } from 'firebase/database';
import { styled } from '@mui/material/styles';
import CurrentOrder from './CurrentOrder';
import WarningChatModal from '../Sell/WarningChatModal';
import { isUserExits } from '../../Constants/Functions';
import { Context } from '../../Utils/ContextAPI/ContextProvider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const imgurl = "https://shreddersbay.com/API/uploads/";

function Orderdetails(bookingId) {
     const db = getDatabase();


     const navigate = useNavigate();
     const [gState, setGState, LogOut, gProductForm, setgProductForm, catagorySelectedData, setcatagorySelectedData, chatGState, setChatGState] = useContext(Context);
     const {  firebase_uid } = gState;

     const location = useLocation();
     const { orderData, allimg } = location.state || {};// Access the passed data
     const [isHover, setisHover] = useState(false);
     console.log("all imgs =:-", allimg);
     // const [orderData, setOrderData] = useState(null);
     // const [imageFilenames, setImageFilenames] = useState([]);

  
     const handleMouseEnter = () => setisHover(true);
     const handleMouseLeave = () => setisHover(false);

     const Item = styled(Paper)(({ theme }) => ({
          backgroundColor: '#fff',
          ...theme.typography.body2,
          padding: theme.spacing(1),
          textAlign: 'center',
          color: theme.palette.text.secondary,
          ...theme.applyStyles('dark', {
               backgroundColor: '#1A2027',
          }),
     }));
     const handleaccept = async (bookingId, userId) => {
          const formData = new FormData();
          formData.append('booking_id', bookingId);
          formData.append('user_id', userId);

          const url = "https://shreddersbay.com/API/orders_api.php?action=accept";

          try {
               const response = await fetch(url, {
                    method: 'POST',
                    body: formData,
               });

               if (response.ok) {
                    const data = await response.json();
                    console.log('Response:', data);
                    // Optionally, add navigation or user feedback here
                    navigate('/CurrentOrder', { state: { orderData: data[0] } });
                   
               } else {
                    console.error('Error:', response.statusText);
               }
          } catch (error) {
               console.error('Error uploading data:', error);
          }
     };
     const [b_userData, setB_userData] = useState({});

    
     // const handleChatOpen = async (data) => {
     //      console.log("Data for handle chat open in OrderDetails =>", data);
      
     //      const userExists = isUserExits(gState.user_name, gState.user_email, gState.user_id);
     //      console.log("The result of userExists =>", userExists);
      
     //      if (userExists) {
     //          const { firebase_uid } = gState; // Current user's Firebase UID
     //          const { firebase_uid: bfirebaseUID, firebase_prodID: bfirebaseProdID } = data; // From the data parameter
      
     //          if (firebase_uid && bfirebaseProdID && bfirebaseUID) {
     //              // Set chat global state
     //              setChatGState({
     //                  bfirebaseUID: bfirebaseUID,
     //                  bfirebaseProdID: bfirebaseProdID,
     //              });
      
     //              // Directly call checkConversationExists with the relevant parameters
     //              await checkConversationExists(firebase_uid, bfirebaseUID, bfirebaseProdID);
     //          } else {
     //              alert("Choose properly an item.");
     //          }
     //      } else {
     //          console.log("Please sign in before chatting.");
     //          alert("Please sign in before chatting.");
     //      }
     //  };
      
     //  const checkConversationExists = async (firebase_uid, bfirebaseUID, bfirebaseProdID) => {
     //      const dbRef = ref(db);
      
     //      // Create conversation IDs in both formats
     //      const conversationId1 = `${firebase_uid}_${bfirebaseUID}_${bfirebaseProdID}`;
     //      const conversationId2 = `${bfirebaseUID}_${firebase_uid}_${bfirebaseProdID}`;
      
     //      try {
     //          // Check if conversation with ID1 exists
     //          const convo1Snapshot = await get(child(dbRef, `messages/${conversationId1}`));
      
     //          // Check if conversation with ID2 exists (in case sender/receiver are swapped)
     //          const convo2Snapshot = await get(child(dbRef, `messages/${conversationId2}`));
      
     //          let conversationId;
      
     //          // Prevent creating a new conversation if the senderId and receiverId are the same
     //          if (firebase_uid === bfirebaseUID) {
     //              console.log('Sender and receiver are the same, no new conversation will be created.');
     //              return;
     //          }
      
     //          // Check if conversations already exist in either format
     //          if (convo1Snapshot.exists()) {
     //              conversationId = conversationId1;
     //              console.log(`Using existing conversationId1: ${conversationId}`);
     //          } else if (convo2Snapshot.exists()) {
     //              conversationId = conversationId2;
     //              console.log(`Using existing conversationId2: ${conversationId}`);
     //          } else {
     //              // If no conversation exists, create a new one with conversationId1
     //              conversationId = conversationId1;
     //              console.log(`Creating new conversation with conversationId: ${conversationId}`);
                  
     //              // Define the initial conversation structure
     //              const newConversation = {
     //                  participants: {
     //                      senderId: firebase_uid, // Current user
     //                      receiverId: bfirebaseUID, // Other user
     //                      productId: bfirebaseProdID, // Product ID
     //                      createdAt: Date.now(), // Timestamp of conversation creation
     //                      all_messages: [] // Empty array for messages
     //                  },
     //              };
     //              setChatGState({
                   
     //               conversationID:conversationId,
     //            });
      
     //              // Create a new conversation in the Firebase database
     //              await set(ref(db, `messages/${conversationId}`), newConversation);
     //              console.log('New conversation created successfully.');
     //          }
      
     //          // After handling the conversation, navigate to the chat inbox
     //          await navigate('/chatinbox');
     //      } catch (error) {
     //          console.error('Error checking or creating conversation:', error);
     //      }
     //  };
      

     const handleBackClick = () => {
          navigate('/');
       };
   

     const handleChatOpen = async (data) => {
          console.log("Data for handle chat open in OrderDetails =>", data);
      
          const userExists = await isUserExits(gState.user_name, gState.user_email, gState.user_id); // Ensure isUserExits is awaited if it's an async function
          console.log("The result of userExists =>", userExists);
      
          if (userExists) {
              const { firebase_uid } = gState; // Current user's Firebase UID
              const { firebase_uid: bfirebaseUID, firebase_prodID: bfirebaseProdID } = data; // From the data parameter
      
              if (firebase_uid && bfirebaseProdID && bfirebaseUID) {
                  // Directly call checkConversationExists with the relevant parameters
                  const conversationId = await checkConversationExists(firebase_uid, bfirebaseUID, bfirebaseProdID);
      
                  // Only set chatGState and navigate if conversationId is valid
                  if (conversationId) {
                      setChatGState({
                          bfirebaseUID,
                          bfirebaseProdID,
                          conversationID: conversationId, // Ensure conversationID is set
                      });
      
                      await navigate('/chatinbox');
                  }
              } else {
                  alert("Choose properly an item.");
              }
          } else {
              console.log("Please sign in before chatting.");
              alert("Please sign in before chatting.");
          }
      };
      
      const checkConversationExists = async (firebase_uid, bfirebaseUID, bfirebaseProdID) => {
          const dbRef = ref(db);
      
          // Create conversation IDs in both formats
          const conversationId1 = `${firebase_uid}_${bfirebaseUID}_${bfirebaseProdID}`;
          const conversationId2 = `${bfirebaseUID}_${firebase_uid}_${bfirebaseProdID}`;
      
          try {
              // Check if conversation with ID1 exists
              const convo1Snapshot = await get(child(dbRef, `messages/${conversationId1}`));
      
              // Check if conversation with ID2 exists (in case sender/receiver are swapped)
              const convo2Snapshot = await get(child(dbRef, `messages/${conversationId2}`));
      
              let conversationId;
      
              // Prevent creating a new conversation if the senderId and receiverId are the same
              if (firebase_uid === bfirebaseUID) {
                  console.log('Sender and receiver are the same, no new conversation will be created.');
                  return null;
              }
      
              // Check if conversations already exist in either format
              if (convo1Snapshot.exists()) {
                  conversationId = conversationId1;
                  console.log(`Using existing conversationId1: ${conversationId}`);
              } else if (convo2Snapshot.exists()) {
                  conversationId = conversationId2;
                  console.log(`Using existing conversationId2: ${conversationId}`);
              } else {
                  // If no conversation exists, create a new one with conversationId1
                  conversationId = conversationId1;
                  console.log(`Creating new conversation with conversationId: ${conversationId}`);
                  
                  // Define the initial conversation structure
                  const newConversation = {
                      participants: {
                          senderId: firebase_uid, // Current user
                          receiverId: bfirebaseUID, // Other user
                          productId: bfirebaseProdID, // Product ID
                          createdAt: Date.now(), // Timestamp of conversation creation
                          all_messages: [] // Empty array for messages
                      },
                  };
      
                  // Create a new conversation in the Firebase database
                  await set(ref(db, `messages/${conversationId}`), newConversation);
                  console.log('New conversation created successfully.');
              }
      
              // Return the conversationId for further use
              return conversationId;
          } catch (error) {
              console.error('Error checking or creating conversation:', error);
              return null;
          }
      };

      const buttonStyle = {
          border: '1px solid #00457E',
          backgroundColor: isHover ? 'transparent' : '#00457E',
          width: '200px',
          padding: '6px',
          color: isHover ? '#00457E':'#ffffff',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease-in-out',
      };
      
     return (

          <Box>

<Box
                    sx=
                    {{
                         display: 'flex',
                         alignItems: 'center',
                         padding: '10px',
                         backgroundColor: '#eee',
                         marginTop:{xs:7, md:10},
                         boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
               >
                    <ArrowBackIcon
                         sx={{ cursor: 'pointer', marginLeft: '20px' }}
                         onClick={handleBackClick}
                    />

               </Box>
               {/* <img src={ImagesApp.banner} width={"100%"} /> */}
               <Box className='main-slide'>

                    <Carousel

                         autoPlay={true}
                         interval={3000} // 3 seconds interval
                         infiniteLoop={true} // Loop the slides infinitely
                         showThumbs={false} // Hide the thumbnails
                         showStatus={false} // Hide the status
                         dynamicHeight={true} // Adjusts height based on content
                         stopOnHover={true} // Stops autoplay on hover
                    >

                         {allimg.map((filename, index) => (
                              <div key={index}
                                   style=
                                   {{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '400px',
                                        backgroundColor: 'black',
                                   }}
                              >
                                   <img
                                        src={`${imgurl}${filename}`}
                                        alt={`Slide ${index + 1}`}
                                        style={{
                                             maxHeight: '100%',
                                             maxWidth: '100%',
                                             objectFit: 'contain',
                                             borderRadius: '10px' // Adds rounded corners
                                        }}
                                   />
                              </div>
                         ))}

                    </Carousel>



               </Box>

               <Box sx={{ margin: 0 }}>

                    <Box sx={{
                         backgroundColor: '#ebf1ff',
                         padding: { xs: 0, sm: 6 },
                    }}
                    >
                         <Grid container spacing={2}>
                              <Grid item xs={12} md={8}>
                                   <Item>

                                        <Box
                                             sx={{
                                                  padding: 2,
                                                  border: 1,
                                                  borderColor: '#eee',
                                                  fontSize: 20,
                                             }}
                                        >
                                             <Grid container spacing={2}>
                                                  <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                       <strong>Product Detail:</strong>
                                                  </Grid>

                                             </Grid>
                                        </Box>

                                        <Box sx=
                                             {{
                                                  padding: { xs: 0, sm: 3 },
                                                  marginTop: 1,
                                                  border: 1,
                                                  borderColor: '#eee',
                                                  textAlign: 'left',
                                             }}
                                        >

                                             <Grid container spacing={2}>
                                                  <Grid item xs={6} style={{ textAlign: 'left' }}>
                                                       <Box sx={{ padding: 2 }}>Product_Name:</Box>
                                                       <Box sx={{ padding: 2 }}>Product_Weight:</Box>
                                                       <Box sx={{ padding: 2 }}> Address:</Box>
                                                       <Box sx={{ padding: 2 }}>Booking_Date:</Box>

                                                  </Grid>
                                                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                                                       <Box sx={{ padding: 2, color: 'red' }} >{orderData.p_name ? orderData.p_name : '--'}</Box>
                                                       <Box sx={{ padding: 2, color: 'red' }} >
                                                            {orderData.total_weight ? orderData.total_weight : '--'}
                                                       </Box>
                                                       <Box sx={{ padding: 2, color: 'red' }}>{orderData.address ? orderData.address : '--'}</Box>
                                                       <Box sx={{ padding: 2, color: 'red' }}>{orderData.booking_date ? orderData.booking_date : '--'}</Box>

                                                  </Grid>
                                             </Grid>


                                        </Box>



                                        <Box
                                             sx={{
                                                  padding: 2,
                                                  border: 1,
                                                  borderColor: '#eee',
                                                  fontSize: 20,
                                             }}
                                        >
                                             <Grid container spacing={2}>
                                                  <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                       <strong>Customer Detail:</strong>
                                                  </Grid>

                                             </Grid>
                                        </Box>

                                        <Box sx=
                                             {{
                                                  padding: 3,
                                                  marginTop: 1,
                                                  border: 1,
                                                  borderColor: '#eee',
                                                  textAlign: 'left',
                                             }}
                                        >

                                             <Grid container spacing={2}>
                                                  <Grid item xs={6} style={{ textAlign: 'left' }}>
                                                       <Box sx={{ padding: 2 }}>Customer_Name:</Box>
                                                       <Box sx={{ padding: 2 }}> Customer_MobileNo:</Box>
                                                       <Box sx={{ padding: 2 }}>Customer_Address:</Box>
                                                       <Box sx={{ padding: 2 }}>Customer_landmark:</Box>
                                                       <Box sx={{ padding: 2 }}>Customer_Pincode:</Box>




                                                  </Grid>

                                                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                                                       <Box sx={{ padding: 2, color: 'red' }} >{orderData.name ? orderData.name : '--'}</Box>
                                                       <Box sx={{ padding: 2, color: 'red' }}>{orderData.mobile ? orderData.mobile : '--'}</Box>
                                                       <Box sx={{ padding: 2, color: 'red' }}>{orderData.address ? orderData.address : '--'}</Box>
                                                       <Box sx={{ padding: 2, color: 'red' }}>{orderData.landmark ? orderData.landmark : '--'}</Box>
                                                       <Box sx={{ padding: 2, color: 'red' }}>{orderData.pin_code ? orderData.pin_code : '--'}</Box>


                                                  </Grid>
                                             </Grid>



                                        </Box>


                                   </Item>
                              </Grid>


                              <Grid item xs={12} md={4}>
                                   <Item>
                                        <Box
                                             sx={{
                                                  padding: 2,
                                                  border: 1,
                                                  borderColor: '#eee',
                                                  fontSize: 20,


                                             }}
                                        >
                                             <Grid container spacing={2} style={{ marginBottom: 8 }} >
                                                  <Grid item xs={6} style={{ textAlign: 'left' }}>
                                                       <strong>Approx_Price:</strong>
                                                  </Grid>
                                                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                                                       <span >{orderData.approx_price
                                                       }</span>
                                                  </Grid>

                                             </Grid>

                                             <Box
                                                  sx={{
                                                       display: 'flex',
                                                       justifyContent: 'center',
                                                       alignItems: 'center',
                                                       gap: 4,
                                                       mt: 5, // Optional: Add margin on top for spacing
                                                  }}
                                             >
                                                  <button
                                                       onClick={() => handleaccept(orderData.booking_id, orderData.user_id)}
                                                       onMouseEnter={handleMouseEnter}
                                                       onMouseLeave={handleMouseLeave}
                                                       style={buttonStyle}
                                                  >
                                                       Buy
                                                  </button>

                                                  <button
                                                       style={{
                                                            border: '1px solid #00457E', // Change the border color
                                                            backgroundColor: '#00457E', // Change the background color
                                                            width: '200px', // Set the width
                                                            padding: '6px', // Optional: add padding
                                                            color: '#ffffff', // Optional: change text color
                                                            cursor: 'pointer', // Optional: change cursor to pointer
                                                       }}
                                                       onClick={() => handleChatOpen(orderData)}
                                                  >
                                                       Chat
                                                  </button>
                                             </Box>
                                        </Box>
                                        {/* <Box sx=
                                        {{
                                             padding: 3,
                                             marginTop: 1,
                                             textAlign: 'left',
                                        }}>
                                        <strong>Product Price:</strong> <span style={redTextStyle}>{orderData.price}</span>
                                   </Box> */}
                                   </Item>
                                   {/* <p>{JSON.stringify(orderData)}</p> */}
                              </Grid>


                         </Grid>


                    </Box>



               </Box>
          </Box>



     );
}

export default Orderdetails;
