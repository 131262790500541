import React from 'react'
import { useEffect, useState,useContext } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import "../Home/Getauction.css";
import Skeleton from '@mui/material/Skeleton';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { firstImg } from '../../Constants/Functions';
import { Context } from '../../Utils/ContextAPI/ContextProvider';



function Getauction() {

  const navigate = useNavigate();
  const location = useLocation();
     
     const [data,setData] = useState([]);
     const [gState, setGState, LogOut, gProductForm, setgProductForm] = useContext(Context);
     const { user_id, user_name, user_email } = gState;
     const imgurl = "https://shreddersbay.com/API/uploads/";


useEffect(()=>{
     fetch(`https://shreddersbay.com/API/auctionOrder_api.php?action=select&userId=${user_id || ""}`).then((result)=>
          {
          result.json().then((resp)=>
          {
               // console.warn("result", resp)
               setData(resp);
          });
         
     })
},[user_id])
console.log("result", data)

const handledetails = async (auctionId, item) => {
  const allimgsArray = item.filename.split(','); // Split the images

  console.log("setallimgs=:>>", item.filename); // Ensure this prints an array of images

  const formData = new FormData();
  formData.append('auction_id', auctionId);
  const apiurl = "https://shreddersbay.com/API/auction_api.php?action=select_id";
  
  try {
    const result = await fetch(apiurl, {
      method: 'POST',
      body: formData,
    });

    if (result.ok) {
      const data = await result.json();
      console.log('view Auction.order', data);
      navigate('/auctiondetail', { state: { orderData: data[0], allimg: allimgsArray } }); // Pass allimg here
    }

  } catch (error) {
    console.error("Error fetching auction details:", error);
  }
};


  return (
     <div className='App'>
    
     <h3 style={{textAlign: 'left', color: '#00457E', margin: "20px 10%"}}>New Auction</h3>
 
  
     <div className='container-1'>
     <Box
       sx={{
         display: 'flex',
         flexWrap: 'wrap',
         justifyContent: 'space-between', // Adjusts the space between cards
         gap: 2,
         padding: 2, // Adds padding to the container
       }}
     >
 
      
       {data.map((item, index) => (
         <Card  key={index}
 
         sx={{
          maxWidth: '30%', // Ensures three cards per row with some space in between
          flex: '1 1 calc(33.333% - 16px)', // Ensures each card takes 1/3 of the row minus the gap
          height: { xs: 300, sm: 400, md: 300 },
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          transition: 'transform 0.3s ease-in-out, background-color 0.3s ease-in-out',
          '&:hover': {
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0.3, 0.4, 0.5, 0.2))', // Apply linear gradient
            transform: 'scale(1.05)', // Slightly scale the card on hover
            '& .hoverDetail': {
              opacity: 1, // Show the detail text on hover
            },
          },
          '@media (max-width: 768px)': {
            maxWidth: '48%',
            flex: '1 1 calc(50% - 16px)',
          },
          '@media (max-width: 480px)': {
            maxWidth: '50%',
            flex: '1 1 calc(50% - 16px)',
          },
          position: 'relative',
        }}
 
         onClick={()=>handledetails(item.auction_id,item)}

      
         >
           
         
 
 {item ?(
           <CardMedia        
           sx={{
            height: { xs: 120, sm: 140, md: 200 }, // Responsive heights
            backgroundSize: { xs: 'contain', sm: 'cover' }, // 'contain' for mobile to avoid stretching
            backgroundPosition: 'center', // Center the image
            width: { xs: '100%', sm: '100%' }      // Full width
          }}
          image={`${imgurl}${firstImg(item.filename)}`}
           />
 ) :
 (
 <Skeleton variant="rectangular" width={210} height={118} />
 )}
 
           <CardContent sx={{ flexGrow: 1, textAlign: 'left'}}>
             <Typography gutterBottom variant="body2" 
             sx={{ color: '#00457E', fontSize: '20px', fontWeight: '700' }} >
              {item ? item.p_name : ""}
             </Typography>
             <Typography variant="body2" 
               sx={{ color: 'gray', fontSize: '16px', fontWeight: '700' }}
              >
              <CurrencyRupeeIcon  sx={{ fontSize: 15, color:"#00457E"}}  /> {item ? item.total_price : ""}
             </Typography>
             <Typography variant="body2" 
               sx={{
                color: 'lightgray',
                fontSize: '14px',
                whiteSpace: 'nowrap', // Prevents the text from wrapping
                overflow: 'hidden',   // Ensures the text is hidden when too long
                textOverflow: 'ellipsis', // Adds '...' for truncated text
              }}>
            <LocationOnOutlinedIcon  sx={{ fontSize: 15,  color:"#00457E"}} />  {item ? item.address : ""}
             </Typography>
             <Typography variant="caption" 
              sx={{ color: 'lightgray', fontSize: '14px' }}
              >
              {item ? item.state_name : ""}
             </Typography>
 
             <Typography variant="body2" 
               sx={{ color: 'lightgray', fontSize: '12px', fontWeight: '700' }}
              >
              <CalendarTodayOutlinedIcon sx={{ fontSize: 15,  color:"#00457E"}} /> {item ? item.date : ""}
             </Typography>

            

           </CardContent>

           <Box
    className="hoverDetail"
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0, // Hidden by default
      transition: 'opacity 0.3s ease-in-out', // Smooth transition for showing/hiding
      fontSize: '18px',
      fontWeight: 'bold',
    }}
  >
    Detail
  </Box>
         </Card>
       ))}
        </Box>
     </div>
 
     </div>
  )
}

export default Getauction