

import React, { useState, useContext, useEffect } from "react";
import { TextField, Button, Container, Typography, Grid, Box } from '@mui/material';
import { Context } from "../../Utils/ContextAPI/ContextProvider";
import { Card, CardContent } from '@mui/material';
import './Rating.css';
import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { yellow } from "@mui/material/colors";
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';




function Rating() {
    const [gState] = useContext(Context);
    const { user_id } = gState;
    const navigate = useNavigate();

    const [ratings, setRatings] = useState([]);

    // Define the fields based on your key-value structure
    const fields = [
        { key: "name", value: "", type: "text", enabled: true },
        { key: "mobile", value: "", type: "text", enabled: true },
        { key: "message", value: "", type: "text", enabled: true },
        { key: "rating", value: "", type: "text", enabled: true },
        { key: "address", value: "", type: "text", enabled: true },
        { key: "landmark", value: "", type: "text", enabled: false }
    ];

    // Define state for form data
    const [formData, setFormData] = useState(
        fields.reduce((acc, field) => {
            if (field.enabled) {
                acc[field.key] = field.value;
            }
            return acc;
        }, {})
    );
    useEffect(() => {
        // Function to fetch ratings data
        const fetchRatings = async () => {
            try {
                const response = await fetch('https://shreddersbay.com/API/rating_api.php?action=select');
                const data = await response.json();
                // Assuming data is an array of rating objects
                setRatings(data);
            } catch (error) {
                console.error('Error fetching ratings:', error);
            }
        };

        fetchRatings();
    }, []);

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const renderStars = (rating) => {
        const totalStars = 5;
        let stars = [];

        for (let i = 1; i <= totalStars; i++) {
            if (i <= rating) {
                stars.push(<FaStar key={i} color="ffd500" />);  // Filled star
            } else {
                stars.push(<FaRegStar key={i} color="grey" />);  // Empty star
            }
        }
        return stars;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create FormData object
        const data = new FormData();
        Object.keys(formData).forEach((key) => {
            data.append(key, formData[key]);
        });

        try {
            // Send a POST request to your API
            const response = await fetch(
                "https://shreddersbay.com/API/rating_api.php?action=insert",
                {
                    method: "POST",
                    body: data,
                }
            );

            const result = await response.json();
            console.log(result);

            // Reset form data after successful submission
            setFormData(
                fields.reduce((acc, field) => {
                    if (field.enabled) {
                        acc[field.key] = '';
                    }
                    return acc;
                }, {})
            );

        } catch (error) {
            console.error("Error submitting feedback:", error);
        }
    };


    const handleBackClick = () => {
        navigate('/');
     };

    return (
<Box>

<Box
            sx=
            {{
               display: 'flex',
               alignItems: 'center',
               padding: '10px',
               backgroundColor: '#eee',
               marginTop:10,
               boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }}
         >
            <ArrowBackIcon
               sx={{ cursor: 'pointer', marginLeft: '20px' }}
               onClick={handleBackClick}
            />

         </Box>
<Box sx={{
            display: "flex",
            flexDirection: { xs: 'column', sm: 'row', md: 'row' }
        }}>
            <Box

                sx={{
                    width: { xs: '100%', sm: '50%', md: '50%' },
                    padding: 3,


                }}
            >
                <ul
                    style={{
                        listStyleType: 'none',
                        padding: 0,
                        maxHeight: '500px', // Adjust the height according to your needs
                        overflowY: 'scroll', // Enable vertical scroll
                    }}>
                    {ratings.map(rating => (
                        <li key={rating.id} className="rating-card">
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        <strong>Name:</strong> {rating.name}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Rating:</strong> {renderStars(rating.rating)}

                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        <strong>Message:</strong> {rating.message}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </li>
                    ))}
                </ul>
            </Box>

            <Box sx=
                {{
                    m: 4,
                    p: 3,
                    boxShadow: 3,
                    borderRadius: 2,

                    width: { xs: '80%', sm: '50%', md: '50%' }

                }}>
                <Typography variant="h5" gutterBottom>
                    Rating Form
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {fields.map((field) => (
                            field.enabled && (
                                <Grid item xs={12} key={field.key}>
                                    <TextField
                                        label={field.key.charAt(0).toUpperCase() + field.key.slice(1)}
                                        name={field.key}
                                        type={field.type}
                                        value={formData[field.key] || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            )
                        ))}
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" fullWidth type="submit">
                                Submit Rating
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
</Box>
       


    );
}

export default Rating;





