import React, { useEffect, useState, useContext } from "react";
import { Context } from "../../Utils/ContextAPI/ContextProvider.js";
import { Container, Typography, Card, CardContent, CircularProgress, TextField, Button, Box } from '@mui/material';
import { MdOutlineEmail } from "react-icons/md";
import { MdOutlinePhone } from "react-icons/md";
import { ImagesApp } from "../../Images/ImagesApp.js";


function UpdateProfile() {
  const [gState] = useContext(Context); // Access global state from Context
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // State to toggle edit mode
  const [updatedData, setUpdatedData] = useState({
    name: '',
    email: '',
    mobile: ''
  }); // State to hold updated data for the form

  // Check if user_id is available in context, otherwise get it from localStorage
  const user_id = gState.user_id || localStorage.getItem('user_id');

  // Fetch profile data from the API
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        if (!user_id) {
          console.error('User ID is not available yet.');
          return; // Exit if no user_id
        }

        const response = await fetch(`https://shreddersbay.com/API/user_api.php?action=select_id&user_id=${user_id}`, {
          method: 'POST',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        if (result && result.length > 0) {
          setProfileData(result[0]); // Set profile data from the API response
          setUpdatedData({
            name: result[0].name || '',
            email: result[0].email || '',
            mobile: result[0].mobile || ''
          });
        } else {
          throw new Error('No profile data found');
        }
      } catch (err) {
        console.error("Error fetching profile data:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [user_id]); // Watch for changes to user_id

  // Handle form input changes
  const handleInputChange = (e) => {
    setUpdatedData({
      ...updatedData,
      [e.target.name]: e.target.value
    });
  };

  // Handle form submission and update the profile
  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('user_id', user_id);
      formData.append('name', updatedData.name);
      formData.append('email', updatedData.email);
      formData.append('mobile', updatedData.mobile);

      const response = await fetch('https://shreddersbay.com/API/user_api.php?action=edit', {
        method: 'POST',
        body: formData, // Use FormData instead of JSON if required
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(`Error: ${result.message || response.status}`);
      }

      // Update the profile data with the new data
      setProfileData(updatedData);
      setIsEditing(false);
      alert("Profile updated successfully!");
    } catch (err) {
      console.error("Error updating profile:", err.message);
      setError(err.message);
    }
  };

  // Display loading spinner or error if necessary
  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Box sx={{
         display: 'flex',
         justifyContent:'center',
         alignItems:'center', 
         flexDirection: { xs: 'column', md: 'row' },
         margin:{xs:5,sm:5,md:10},
          gap: 2 
          }}
          >
        {/* Left Box - Profile Details */}
        <Box sx={{
          width: { xs: '100%', md: '50%' },
          display: 'flex',
          flexDirection: 'column',
          mb: { xs: 2, md: 0 } // Add margin bottom on mobile
        }}>
          <Card sx={{
            mt: 4,
            width: '100%',
            height: '330px',
          }}>
            <CardContent sx={{
              textAlign: 'left'
            }}>
              <Typography sx={{ padding: 2, fontSize: '30px', fontWeight: 500 }}><strong >{profileData.name || 'N/A'}</strong></Typography>
              <Typography sx={{ padding: 2, }}><strong  ><MdOutlineEmail size={25}/></strong  > <Typography sx={{color:'#888'}}>{profileData.email || 'N/A'}</Typography></Typography>
              <Typography sx={{ padding: 2 , }}><strong><MdOutlinePhone size={25}/>
              </strong> <Typography sx={{color:'#888'}}>{profileData.mobile || 'N/A'}</Typography></Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center',p: 2}}>
                <Button variant="contained"  
               sx={{
                backgroundColor: '#00457E',  // Set background color
                color: '#fff',               // Set text color
                '&:hover': {                 // Optionally set hover state
                  backgroundColor: '#003366' // Darker shade on hover
                }
              }}
                onClick={() => setIsEditing(true)}>
                  Edit Profile
                </Button>
              </Box>          </CardContent>
          </Card>
        </Box>

        {/* Right Box - Edit Form */}
               {/* Right Box - Edit Form or Image */} 
               <Box sx={{
          width: { xs: '100%', md: '50%' },
          display: 'flex',
          flexDirection: 'column',
          mt: { xs: 2, md: 4 } // Add top margin for mobile view
        }}>
          {isEditing ? (
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Edit Profile</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    label="Name"
                    name="name"
                    value={updatedData.name}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Email"
                    name="email"
                    value={updatedData.email}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Mobile"
                    name="mobile"
                    value={updatedData.mobile}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button variant="contained" 
                      sx={{
                        backgroundColor: '#00457E',  // Set background color
                        color: '#fff',               // Set text color
                        '&:hover': {                 // Optionally set hover state
                          backgroundColor: '#003366' // Darker shade on hover
                        }
                      }}
                      onClick={handleSubmit}>
                      Update
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => setIsEditing(false)}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <img src={ImagesApp.updateProfile} alt="Profile placeholder" style={{ width: '80%', height: 'auto' }} />
            </Box>
          )}
        </Box>

      </Box>
    </Container>

  );
}

export default UpdateProfile;
