import React, { useEffect, useState, useContext } from 'react'
import { Box, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import '../Sell/MySell.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';
import { Url } from '../../Constants/Apiurls';
import UserAuth from '../Credential/UserAuth';
import { Context } from '../../Utils/ContextAPI/ContextProvider';
import LazyLoad from 'react-lazyload';






function MySell() {
   const navigate = useNavigate();
   const [gState, setGState, LogOut, gProductForm, setgProductForm, catagorySelectedData, setcatagorySelectedData] = useContext(Context);
    const { user_name, user_id, user_email } = gState;
   const [data, setData] = useState([]);
   const [loading, setLoading] = useState(true);
   const [selectedData, setSelectedData] = useState([]);
   const [title, setTitle] = useState('');
   const [error, setError] = useState(false);
   const [helperText, sethelperText] = useState('');
   const [desc, setDesc] = useState('');
   const [descerror, setdescError] = useState(false);
   const [deschelperText, setdeschelperText] = useState('');
   const [price, setPrice] = useState('');
   const [priceerror, setpriceError] = useState(false);
   const [pricehelperText, setpricehelperText] = useState('');
   const [Weight, setWeight] = useState('');
   const [weighterror, setweightError] = useState(false);
   const [weighthelperText, setweighthelperText] = useState('')

   const [isUserAuthEnable, setIsUserAuthEnable] = useState(false);
 

   const imgurl = "https://shreddersbay.com/API/uploads/";

   useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(Url.get.Producturl);
          if (!response.ok) throw new Error('Network response was not ok');
          const result = await response.json();
          setData(result);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
   // useEffect(() => {
   //    fetch(Url.get.Producturl).then((result1) => {
   //       result1.json().then((resp) => {
   //          setData(resp)
   //       })
   //    })
   // }, [data,navigate])


   // console.log("result1", data)

   // useEffect(() => {
   //    const storedTitle = localStorage.getItem('title');
   //    const storedDescription = localStorage.getItem('description');
   //    const storedPrice = localStorage.getItem('Price');
   //    const storedWeight = localStorage.getItem('weight');

   //    const storedSelectedData = JSON.parse(localStorage.getItem('selectedData') || '[]');


   //    console.log("title1", localStorage.getItem('title')); // Output: Sample Title
   //    console.log("description", localStorage.getItem('description')); // Output: This is a sample description
   //    console.log(localStorage.getItem('price')); // Output: 100
   //    console.log(localStorage.getItem('weight')); // Output: 1.5
   //    console.log(JSON.parse(localStorage.getItem('selectedData'))); // Output: [{ id: 1, name: 'Example' }]


   //    if (storedTitle) setTitle(storedTitle);
   //    if (storedDescription) setDesc(storedDescription);
   //    if (storedPrice) setPrice(storedPrice);
   //    if (storedWeight) setWeight(storedWeight);
   //    if (storedSelectedData.length) setSelectedData(storedSelectedData);



   // },
   //    []);

   // useEffect(() => {
   //    // Update localStorage when selectedData changes
   //    if (selectedData.length && selectedData[0].price) {
   //       localStorage.setItem('price', selectedData[0].price);
   //    }
   // }, [selectedData]);

   const handleBackClick = () => {
      navigate('/');
   };
   ///////////////////////////////////////////////sub catagory api
   const fetchDataById = (p_id) => {
      console.log("Fetching data for p_id:", p_id); // Debugging log

    
      const formData = new FormData();
      formData.append('p_id', p_id);

      fetch('https://shreddersbay.com/API/product_api.php?action=select_id',
         {
            method: 'POST',
            body: formData,
         })


         .then((response) => {
            console.log('Response:', response); // Debugging log

            if (!response.ok) {
               throw new Error('Network response was not ok');
            }
            return response.json();
         })
         .then((data) => {
            console.log("Fetched data by p_id:", data); // Debugging log

          
            if (!data) {
               console.error('No data returned from API'); // Debugging log

            } else {
               // setChooseSubcatagory(data.length && data[0].p_type_name)
               setSelectedData(data);
               setcatagorySelectedData(data)
               setgProductForm({
                  ...gProductForm,
                  p_id:p_id,
                  price:data[0].price,
                 
               })
               // localStorage.setItem("selectedData", JSON.stringify(data));
               console.log("Selected data set:", data); // Debugging log

            }
         })
         .catch((error) => {
            console.error('Error fetching data by p_id:', error);
         });
   };


  

   

   const handleGridItemClick = (p_id) => {
      console.log('Data fetched for ID:', p_id);
      fetchDataById(p_id);
      setgProductForm({
         ...gProductForm,
         p_id:p_id,
         
      })
     
      navigate(`/ProductForm`);
   };




   return (

      <Box sx={{
         marginTop: {xs:8 , sm: 0, md: 4 }
      }}
      >
         <Box
            sx=
            {{
               display: 'flex',
               alignItems: 'center',
               padding: '10px',
               backgroundColor: '#eee',
               marginTop:{sx: 0, sm:0, md:10},
               boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }}
         >
            <ArrowBackIcon
               sx={{ cursor: 'pointer', marginLeft: '20px' }}
               onClick={handleBackClick}
            />

         </Box>

         <h4 className='ads' style={{ color: '#00457E', fontWeight: '800' }}>POST YOUR ADS</h4>

         <Box sx=
            {{
               border: '1px solid black',
               width: { xs: '100%', md: '70%' },
               marginTop: { xs: '2%', md: '2%' }, // Removes margin on mobile (xs) and keeps it for medium screens (md) and up
               padding: '2%',
               marginRight: { md: 'auto' },
               marginLeft: { md: 'auto' },
            }}
         >

            <Box className='CatText'
               sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5 , marginBottom: 5}}
            >
               <h5>Choose a Category</h5>
            </Box>
            <UserAuth openAuth={isUserAuthEnable} setOpenAuth={setIsUserAuthEnable} />

            <Box>

               
   <Grid container spacing={0}>
      {data.map((item, index) => (
         <Grid item xs={6} key={index} sx={{marginBottom: 4}}
         onClick={() => handleGridItemClick(item.p_id)} 
         
         > 
            <Box sx=


               {{
                   textAlign: 'center',
                   borderRight: '1px solid lightgray',
                   borderBottom: '1px solid lightgray',
                }}


                className='Box_image'
                 > {/* Centering image and text */}
             <LazyLoad height={60} offset={100}>
              <img
                src={`${imgurl}${item.file_name}`}
                alt={item.p_name}
                style={{
               
                  borderRadius: '50%',
                  background: 'cover',
                }}
              />
            </LazyLoad>
               <Box sx={{marginTop: 2, marginBottom: 2, }} style={{ color: '#00457E', fontWeight: '700' }}>
                  {item ? item.p_name : ""}
               </Box>
            </Box>
         </Grid>
      ))}
   </Grid>
</Box>

         

         </Box>


      </Box>
   )
}

export default MySell