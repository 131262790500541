import React from 'react'

function EmptyChatModal({open,onClose}) {
     if(!open){
          return null
     }

  return (
    <div style={{height:"100%",width: "100%",alignItems:'center',justifyContent:"center",color:"black"}}> 
                  <h1>please choose one text</h1>

    </div>
  )
}

export default EmptyChatModal