


import React, { useState } from 'react';
import { Box, IconButton, Button, Typography, TextField, Link, useMediaQuery,InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GWebbtn from '../../Components/Cred/CustomComp/GWebbtn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import {faEyeSlash} from '@fortawesome/free-solid-svg-icons'

const CustomModalSignup = ({ open ,
  handleClose,
  handleSignUp,
  handleOpenSignin,
  images,
  name,
  setName,
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  phone,
  setPhone }) => {

  const isMobile = useMediaQuery('(max-width:600px)');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility=()=>{
    setShowPassword(!showPassword);
  }


 
  if (!open) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1300, // Make sure it is on top of other elements
      }}
    >
      <Box
        sx={{
          width: '80%',
          maxWidth: '900px',
          backgroundColor: 'white',
          borderRadius: '8px',
          overflow: 'hidden',
          position: 'relative',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}>
          {
            !isMobile && (
              <Box
                sx={{
                  width: '50%',
                  backgroundColor: 'darkgray',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 2,
                }}
              >
                <Box>
                  <img
                    src={images}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    alt="Signup"
                  />
                </Box>
                <Box sx={{ marginTop: '40px' }}>
                  <Typography sx={{ alignContent: 'center', fontWeight: 'bold' }}>Welcome to Shreddersbay</Typography>
                </Box>
                <Box sx={{ marginTop: '10px' }}>
                  <Typography sx={{ color: '#00457E', textAlign: 'center' }}>
                    "The future of recycling is not just about reducing waste but creating value from what was once considered scrap."
                  </Typography>
                </Box>
              </Box>
            )}

          <Box sx={{
            width: isMobile ? '100%' : '50%',
            padding: '20px',
          }}>
            <Box component="form" sx={{ mt: 2 }}>
              <Box sx={{ marginTop: 3 }}>
                <TextField
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  sx={{ mb: 1 }}
                  InputProps={{
                    sx: {
                      height: '40px',
                      padding: '0px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      bottom: '10%',
                    },
                  }}
                />
                <TextField
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  sx={{ mb: 1 }}
                  InputProps={{
                    sx: {
                      height: '40px',
                      padding: '0px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      bottom: '10%',
                    },
                  }}
                />
                <TextField
                  label="Password"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{ mb: 1 }}
                  InputProps={{
                    sx: {
                      height: '40px',
                      padding: '0px',
                    },

                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility} edge="end" sx={{marginRight: 2, fontSize: '1.2rem'}}>
                          <FontAwesomeIcon icon={showPassword ? faEye: faEyeSlash } />
                        </IconButton>
                      </InputAdornment>
                    ),

                  }}
                />
                <TextField
                  label="Confirm Password"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  sx={{ mb: 1 }}
                  InputProps={{
                    sx: {
                      height: '40px',
                      padding: '0px',
                    },

                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility} edge="end" sx={{marginRight: 2, fontSize: '1.2rem'}}>
                          <FontAwesomeIcon icon={showPassword ? faEye: faEyeSlash } />
                        </IconButton>
                      </InputAdornment>
                    ),

                  }}
                />
                <TextField
                  label="Phone Number"
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  fullWidth
                  sx={{ mb: 1 }}
                  InputProps={{
                    sx: {
                      height: '40px',
                      padding: '0px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      bottom: '10%',
                    },
                  }}
                />
              </Box>

              <Box sx={{ marginTop: 5 }}>
                <Button variant="contained" sx={{ width: '100%', backgroundColor: '#00457E' }} onClick={handleSignUp} >
                  Sign Up
                </Button>
              </Box>

              <Box sx={{ textAlign: 'center', marginTop: 2, marginBottom: 2, justifyContent: 'center', display: 'flex' }}>
                <GWebbtn handleClose={handleClose}/>
              </Box>

              <Box
                sx={{
                  textAlign: 'center',
                  marginTop: 3,
                  marginBottom: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body1">
                  New Shreddersbay?{' '}
                  <Link underline="hover" sx={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={handleOpenSignin}>
                    Sign In
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomModalSignup;
